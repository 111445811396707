import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import AlertsDashboard from '../custom-components/AlertsDashboard';
import Loader from '../components/Loader';
import { UserContext } from '../contexts/UserContext';
import SingleAlertWidget from '../custom-components/SingleAlertWidget';
import Facilities from '../custom-components/Facilities';
import Users from '../custom-components/Users';
import Settings from '../custom-components/Settings';
import SpecialtyRxNow from '../custom-components/SpecialtyRxNow';
import { WidgetsData } from '../data/WidgetsData';
import ResetPassword from '../pages/auth/ResetPassword';
import Documents from '../custom-components/Documents';
import Forms from '../custom-components/Forms';
import FrameworkVision from '../custom-components/FrameworkVision';
import { Badge } from 'reactstrap';
import FileManager from '../custom-components/FileManager';
import PackingSlips from '../custom-components/PackingSlips';
import Pharmacies from '../custom-components/Pharmacies';
import ReleaseNotes from '../custom-components/ReleaseNotes';
import Versions from '../custom-components/Versions';
import SingleAlertWidgetArchived from '../custom-components/SingleAlertWidgetArchived';
import PharmacyUsers from '../custom-components/admin-components/PharmacyUsers';
import OpenDepositSlip from '../custom-components/OpenDepositSlip';
import ErrorFallbackModal from '../utility-components/ErrorFallbackModal';
import FacilitySettings from '../custom-components/FacilitySettings';
import TableRowDetailPage from '../custom-components/TableRowDetailPage';

import bellCogOutline from '@iconify/icons-mdi/bell-cog-outline';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { faciToken, loginUser, userLoading } = useContext(UserContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!faciToken) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }
                if (userLoading)
                    return (
                        <div className="page-loading-loader-div w-100 d-flex justify-content-center align-items-center">
                            <div>
                                <h4>Loading...</h4>
                                <div className="bouncing-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    );

                // authorised so return component
                return (
                    <ErrorBoundary FallbackComponent={ErrorFallbackModal}>
                        <Component {...props} />
                    </ErrorBoundary>
                );
            }}
        />
    );
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
// const dashboardRoutes = {
//     path: '/dashboard',
//     name: 'Dashboards',
//     icon: 'uil-home-alt',
//     header: 'Navigation',
//     children: [
//         {
//             path: '/dashboard/analytics',
//             name: 'Analytics',
//             component: AnalyticsDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/crm',
//             name: 'CRM',
//             component: CRMDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/ecommerce',
//             name: 'Ecommerce',
//             badge: {
//                 variant: 'success',
//                 text: '3',
//             },
//             component: EcommerceDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/project',
//             name: 'Project',
//             component: ProjectDashboard,
//             route: PrivateRoute,
//         },
//     ],
// };

const dashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-desktop',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: AlertsDashboard,
};

const widgetsRoute = {
    path: '/alerts',
    name: 'Alert Widgets',
    icon: 'uil-window-restore',
    children: WidgetsData.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
        .filter((widget) => widget.active)
        .map((widget) => {
            return {
                path: '/alerts/' + widget.path,
                name: widget.sidebarName,
                component: SingleAlertWidget,
                route: PrivateRoute,
                badge: {
                    variant: 'secondary',
                    text: widget.category,
                },
                exact: true,
            };
        }),
    // children: [
    //     {
    //         path: '/omni/catalog/products',
    //         name: 'Products',
    //         component: Products,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/collections',
    //         name: 'Collections',
    //         component: CollectionCards,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/brands',
    //         name: 'Brands',
    //         component: Brands,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/coupons',
    //         name: 'Coupons',
    //         component: Coupons,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/inventory',
    //         name: 'Inventory',
    //         component: InventoryList,
    //         route: PrivateRoute,
    //     },
    // ],
};

const facilitiesRoute = {
    path: '/facilities',
    name: 'Facilities',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-building',
    header: <hr width="100" className="p-0" style={{ padding: '0px', margin: '0px' }} />,
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Admin</span></Badge>,
    exact: true,
    component: Facilities,
};

const usersRoute = {
    path: '/users',
    name: 'Users',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-user',
    exact: true,
    component: Users,
};

const documentsRoute = {
    path: '/documents',
    name: 'Documents',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-folder',
    exact: true,
    component: Documents,
};

const formsRoute = {
    path: '/secureForms',
    name: 'Secure Forms',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-file-alt',
    // header: <hr width="100" className='p-0' style={{ padding: '0px', margin: '0px' }}  />,
    header: (
        <Badge className="py-1 px-2 badge badge-outline-light" style={{ fontSize: '13px' }}>
            <span>Forms and Documents</span>
        </Badge>
    ),
    exact: true,
    component: Forms,
};

const FileManagerRoute = {
    path: '/files',
    name: 'File Manager',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-file-network',
    exact: false,
    component: FileManager,
};

const PackingSlipsRoute = {
    path: '/packing-slips',
    name: 'Packing Slips',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-invoice',
    exact: false,
    component: PackingSlips,
};

const settingsRoute = {
    path: '/settings',
    name: 'Settings',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-cog',
    header: <hr />,
    component: Settings,
};

const frameworkVisionRoute = {
    path: '/frameworkVision',
    name: 'Framework Vision',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-eye',
    // header: <hr width="100" className='p-0' style={{ padding: '0px', margin: '0px' }}  />,
    header: (
        <Badge className="py-1 px-2 badge badge-outline-light" style={{ fontSize: '13px' }}>
            <span>Apps</span>
        </Badge>
    ),
    component: FrameworkVision,
};
const specialtyRxNow = {
    path: '/specialtyrxnow',
    name: 'SpecialtyRx Now',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-desktop',
    exact: false,
    component: SpecialtyRxNow,
};

const appRoutes = [
    dashboardRoute,
    widgetsRoute,
    // facilitiesRoute,
    // usersRoute,
    formsRoute,
    // documentsRoute,
    FileManagerRoute,
    PackingSlipsRoute,
    frameworkVisionRoute,
    specialtyRxNow,
    // chatAppRoutes,
    // ecommerceAppRoutes,
    // emailAppRoutes,
    // projectAppRoutes,
    // socialAppRoutes,
    // taskAppRoutes,
];


const otherRoutes = {
    path: '/detail',
    name: 'Detail',
    children: [
        {
            path: '/authorization/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/highcostalerts/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/clarifications/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/prior-authorization-medication/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/non-covered/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/therapeutic-interchange/:rxNo',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        }, {
            path: '/admission-medication-review/:admissionMedsGroupID',
            name: 'Table Row Detail Page',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: TableRowDetailPage,
        },
        {
            path: '/settings',
            name: 'Settings',
            route: PrivateRoute,
            roles: ['Admin'],
            icon: 'uil-cog',
            component: Settings,
        },
        {
            path: '/info/release-notes',
            name: 'Release Notes',
            route: PrivateRoute,
            roles: ['Admin'],
            component: ReleaseNotes,
        },
        {
            path: '/info/versions',
            name: 'Versions',
            route: PrivateRoute,
            roles: ['Admin'],
            component: Versions,
        },
        {
            path: '/facility-users',
            name: 'Facility Users',
            route: PrivateRoute,
            roles: ['Admin'],
            component: Users,
        },
        {
            path: '/pharmacy-users',
            name: 'Pharmacy Users',
            route: PrivateRoute,
            roles: ['Admin'],
            component: PharmacyUsers,
        },
        {
            path: '/facilities',
            name: 'Facilities',
            route: PrivateRoute,
            roles: ['Admin'],
            component: Facilities,
        },
        {
            path: '/pharmacies',
            name: 'Pharmacies',
            route: PrivateRoute,
            roles: ['Admin'],
            component: Pharmacies,
        },
        {
            path: '/facility/facilitySettings/:facID',
            name: 'Facility Settings',
            route: PrivateRoute,
            roles: ['Admin'],
            exact: true,
            component: FacilitySettings,
        },
        {
            path: '/open-deposit-slip',
            name: 'Open Deposit Slip',
            component: OpenDepositSlip,
            route: Route,
        },
        {
            path: '/alerts',
            name: 'Alert Widgets',
            icon: 'uil-window-restore',
            children: WidgetsData.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
                .filter((widget) => widget.active)
                .map((widget) => {
                    return {
                        path: '/alerts/' + widget.path + '/archive',
                        name: widget.sidebarName,
                        component: SingleAlertWidgetArchived,
                        route: PrivateRoute,
                        exact: true,
                    };
                }),
            // children: [
            //     {
            //         path: '/omni/catalog/products',
            //         name: 'Products',
            //         component: Products,
            //         route: PrivateRoute,
            //     },
            //     {
            //         path: '/omni/catalog/collections',
            //         name: 'Collections',
            //         component: CollectionCards,
            //         route: PrivateRoute,
            //     },
            //     {
            //         path: '/omni/catalog/brands',
            //         name: 'Brands',
            //         component: Brands,
            //         route: PrivateRoute,
            //     },
            //     {
            //         path: '/omni/catalog/coupons',
            //         name: 'Coupons',
            //         component: Coupons,
            //         route: PrivateRoute,
            //     },
            //     {
            //         path: '/omni/catalog/inventory',
            //         name: 'Inventory',
            //         component: InventoryList,
            //         route: PrivateRoute,
            //     },
            // ],
        },
    ],
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/reset-password',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes, otherRoutes];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
