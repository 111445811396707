import React, { useContext, useEffect } from 'react';
import { useAtom, atom } from 'jotai';
import {
    highCostAtomUserSettings,
    highCostAlertsAtomUserSettings,
    patMissingAtomUserSettings,
    clarificationsAtomUserSettings,
    priorAuthAtomUserSettings,
    nonCoveredAtomUserSettings,
    therapeuticIntAtomUserSettings,
    admissionMedsAtomUserSettings,
} from '../jotai-atoms/AlertAtoms';
import { UserContext } from '../contexts/UserContext';

const useAlertsUserSettings = () => {
    const { user } = useContext(UserContext);
    const [highCostUserSettings, setHighCostUserSettings] = useAtom(highCostAtomUserSettings);
    const [highCostAlertsUserSettings, setHighCostAlertsUserSettings] = useAtom(highCostAlertsAtomUserSettings);
    const [nonCoveredUserSettings, setNonCoveredUserSettings] = useAtom(nonCoveredAtomUserSettings);
    const [clarificationsUserSettings, setClarificationsUserSettings] = useAtom(clarificationsAtomUserSettings);
    const [priorAuthUserSettings, setPriorAuthUserSettings] = useAtom(priorAuthAtomUserSettings);
    const [therapeuticIntUserSettings, setTherapeuticIntUserSettings] = useAtom(therapeuticIntAtomUserSettings);
    const [admissionMedsUserSettings, setAdmissionMedsUserSettings] = useAtom(admissionMedsAtomUserSettings);
    const [patMissingUserSettings, setPatMissingUserSettings] = useAtom(patMissingAtomUserSettings);

    const getRequests = [
        {
            path: 'highCost',
            stateName: 'highCost',
            setter: setHighCostUserSettings,
            getter: highCostUserSettings
        },
        {
            path: 'highcostalerts',
            stateName: 'highCostAlerts',
            setter: setHighCostAlertsUserSettings,
            getter: highCostAlertsUserSettings
        },
        {
            path: 'openbatch',
            stateName: 'openBatch',
            setter: setClarificationsUserSettings,
            getter: clarificationsUserSettings
        },
        {
            path: 'nonCovered',
            stateName: 'nonCovered',
            setter: setNonCoveredUserSettings,
            getter: nonCoveredUserSettings
        },
        {
            path: 'priorAuth',
            stateName: 'priorAuth',
            setter: setPriorAuthUserSettings,
            getter: priorAuthUserSettings
        },
        {
            path: 'therapeuticint',
            stateName: 'theraInt',
            setter: setTherapeuticIntUserSettings,
            getter: therapeuticIntUserSettings
        },
        {
            path: 'admissionmeds',
            stateName: 'admissionMeds',
            setter: setAdmissionMedsUserSettings,
            getter: admissionMedsUserSettings
        },
        // {
        //     path: 'patmissing',
        //     stateName: 'patMissingList',
        //     setter: setPatMissingUserSettings,
        //     getter: patMissingUserSettings
        // }
    ]

    const setSortOrder = async ({ path, getter, setter, stateName }) => {
        let sort = `widget_${stateName}_SortOrder`
        let hidden = `widget_${stateName}_Show`
        setter({ sortOrder: Number(user.userSettings[sort]), hidden: (user.userSettings[hidden] === 'true' ? false : true) })
    }

    useEffect(() => {
        if (user) {
            getRequests.forEach((req) => {
                setSortOrder(req)
            })
        }
    }, [user])

}

export default useAlertsUserSettings;