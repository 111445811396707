import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import {
    Modal,
    ModalHeader,
    UncontrolledCollapse,
    Badge,
    ModalFooter,
    ModalBody,
    Card,
    CardHeader,
    CardBody,
    Button,
    ButtonGroup,
    UncontrolledButtonDropdown,
    Row,
    DropdownToggle,
    InputGroup,
    InputGroupAddon,
    DropdownMenu,
    DropdownItem,
    Col,
    Media,
    UncontrolledDropdown,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    UncontrolledTooltip,
    Alert,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Select from 'react-select';
import { SrxApi } from '../api/SrxApi';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { confirm } from '../utility-components/ConfirmModal';
import { Icon } from '@iconify/react';
import accountIcon from '@iconify/icons-mdi/account';
import { useContext } from 'react';
import { CurrentTimeContext } from '../contexts/CurrentTimeContext';
import { useSetAtom } from 'jotai';
import checkBold from '@iconify/icons-mdi/check-bold';
import closeThick from '@iconify/icons-mdi/close-thick';
import clockMinusOutline from '@iconify/icons-mdi/clock-minus-outline';
import numeric1CircleOutline from '@iconify/icons-mdi/numeric-1-circle-outline';
import helpCircleOutline from '@iconify/icons-mdi/help-circle-outline';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { UserContext } from '../contexts/UserContext';
import generatePDF from '../utility-services/reportGenerator';
import contentSave from '@iconify/icons-mdi/content-save';
import printerIcon from '@iconify/icons-mdi/printer';
import emailOutline from '@iconify/icons-mdi/email-outline';
import emailOpenOutline from '@iconify/icons-mdi/email-open-outline';
import useUpdateAlert from '../custom-hooks/useUpdateAlert';
import useOnScreen from '../custom-hooks/useIsVisibleOnScreen';
import downloadIcon from '@iconify/icons-mdi/download';
import { SelectedPharmaciesContext } from '../contexts/SelectedPharmaciesContext';
import { SelectedFacilitiesContext } from '../contexts/SelectedFacilitiesContext';
import TableRowDetailPage from './TableRowDetailPage';
import { decode } from 'he';

const generateFileName = (string) => string.replace(/[^a-z0-9]/gi, '_').toLowerCase();

const getUserActionBadgeColor = (action) => {
    return 'secondary';
    if (action.toLowerCase() === 'approve') return 'success';
    if (action.toLowerCase() === 'deny') return 'danger';
    if (action.toLowerCase() === 'approveonce') return 'secondary';
    if (action.toLowerCase() === 'ask') return 'warning';
    if (action.toLowerCase() === 'submitlimit') return 'secondary';
    if (action.toLowerCase() === 'reply') return 'secondary';
};

const getActionLabel = (action) => {
    if (action.toLowerCase() === 'approve') return 'Approve';
    if (action.toLowerCase() === 'deny') return 'Deny';
    if (action.toLowerCase() === 'approveonce') return 'Approve Once';
    // if(action.toLowerCase() === 'ask')
    //     return 'Ask'
    if (action.toLowerCase() === 'submitlimit') return 'Limit Approval';
    if (action.toLowerCase() === 'send') return 'Send';
    if (action.toLowerCase() === 'donotsend') return 'Do Not Send';
    // if(action.toLowerCase() === 'reply')
    //     return 'Reply'
    return null;
};



const TableRowDetailModal = ({ isOpen, toggle, alertInfo }) => {
    const { alert, stateName, name, category, tab, requestActionPermissionWidgetName } = alertInfo;

    const checkActionPermissions = (requestActions, widgetName, pharmacies, pharmId, facID) => {
        return requestActions.filter(({ action }) => {
            if (action === 'ask') {
                if (user.userType === 'PharmacyUser') {
                    const isPharmacyActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.userPermissions?.[`widget_${widgetName}_Action_ask`]
                        && pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_ask`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_ask`];
                    return isPharmacyActionAllowed ? true : false;
                } else if (user.userType === 'FacilityUser') {
                    const isFacilityActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_ask`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_ask`];
                    return isFacilityActionAllowed ? true : false;
                }

            } else if (action === 'approve') {
                if (user.userType === 'PharmacyUser') {
                    const isPharmacyActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.userPermissions?.[`widget_${widgetName}_Action_approve`]
                        && pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_approve`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_approve`];
                    return isPharmacyActionAllowed ? true : false;
                } else if (user.userType === 'FacilityUser') {
                    const isFacilityActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_approve`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_approve`];
                    return isFacilityActionAllowed ? true : false;
                }

            } else if (action === 'deny') {
                if (user.userType === 'PharmacyUser') {
                    const isPharmacyActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.userPermissions?.[`widget_${widgetName}_Action_deny`]
                        && pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_deny`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_deny`];
                    return isPharmacyActionAllowed ? true : false;
                } else if (user.userType === 'FacilityUser') {
                    const isFacilityActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_deny`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_deny`];
                    return isFacilityActionAllowed ? true : false;
                }

            } else if (action === 'submitlimit') {
                if (user.userType === 'PharmacyUser') {
                    const isPharmacyActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.userPermissions?.[`widget_${widgetName}_Action_limit`]
                        && pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_limit`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_limit`];
                    return isPharmacyActionAllowed ? true : false;
                } else if (user.userType === 'FacilityUser') {
                    const isFacilityActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_limit`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_limit`];
                    return isFacilityActionAllowed ? true : false;
                }

            } else if (action === 'approveonce') {
                if (user.userType === 'PharmacyUser') {
                    const isPharmacyActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.userPermissions?.[`widget_${widgetName}_Action_once`]
                        && pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_once`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_once`];
                    return isPharmacyActionAllowed ? true : false;
                } else if (user.userType === 'FacilityUser') {
                    const isFacilityActionAllowed =
                        pharmacies?.find((pharm) => pharm.pharmID === pharmId)?.pharmPermissions?.[`widget_${widgetName}_Action_once`]
                        && facilities?.find((facility) => facility.facID === facID)?.permissions?.[`widget_${widgetName}_Action_once`];
                    return isFacilityActionAllowed ? true : false;
                }

            } else {
                return false;
            }
        });
    };
    const tableRowDetailPagelink = () => {
        const domain = window.location.origin;
        if (category === "highCost") {
            return (
                `${domain}` + '/' + 'authorization' + '/' + alert.rxNo
            );
        } else if (category === "highcostalerts") {
            return (
                `${domain}` + '/' + 'highcostalerts' + '/' + alert.rxNo
            );
        } else if (category === "clarifications") {
            return (
                `${domain}` + '/' + 'clarifications' + '/' + alert.rxNo
            );
        } else if (category === "priorAuth") {
            return (
                `${domain}` + '/' + 'prior-authorization-medication' + '/' + alert.rxNo
            );
        } else if (category === "nonCovered") {
            return (
                `${domain}` + '/' + 'non-covered' + '/' + alert.rxNo
            );
        } else if (category === "therapeuticInt") {
            return (
                `${domain}` + '/' + 'therapeutic-interchange' + '/' + alert.rxNo
            );
        } else if (category === "admissionMeds") {
            return (
                `${domain}` + '/' + 'admission-medication-review' + '/' + alert.admissionMedsGroupID
            );
        }
    };


    const { user } = useContext(UserContext);


    const { pharmacies } = useContext(SelectedPharmaciesContext);

    const { facilities } = useContext(SelectedFacilitiesContext);

    const [activeTab, setActiveTab] = useState('detail');

    function getDateTime(date) {
        let dateAndTime = new Date(date).toLocaleDateString();

        return dateAndTime;
    }

    function getDateAndTime(date) {
        let onlyDate = new Date(date).toLocaleString();
        return onlyDate;
    }

    function getDateAndTimeStamp(date) {
        const uTCZero = `${date}` + 'Z';

        let onlyDate = new Date(uTCZero).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });

        return onlyDate;
    }

    function capitalizeFirstLetter(word) {
        return word[0].toUpperCase() + word.substring(1);
    }

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '31.94px',
            height: '31.94px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '31.94px',
            padding: '0 6px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '31.94px',
        }),
    };

    const [loadingUserAction, setLoadingUserAction] = useState(false);

    const [actionComment, setActionComment] = useState('');
    const [limitDays, setLimitDays] = useState(null);

    const handleUserAction = async (action, actionComment, widget, limitDays = null) => {
        let id;
        if (widget === 'AdmissionMeds') {
            id = alert.admissionMedsGroupID;
        } else {
            id = alert._internalID;
        }

        let payload = {
            requestedAction: action,
            widget, // this needs to be changed to be dynamic when additional widgets are added
            _internalID: id,
            actionComment,
        };

        if (action.toLowerCase() === 'submitlimit'.toLocaleLowerCase()) payload.limitDays = limitDays;

        setLoadingUserAction(true);

        let sendUserActionToast = toast.loading('Processing user action...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        SrxApi.post('/requestaction', payload)
            .then((res) => {
                setActionComment('');
                toast.update(sendUserActionToast, {
                    render: res.data.message,
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
                if (action.toLowerCase() === 'approve') {
                    toggleCommentModal();
                } else if (action.toLowerCase() === 'deny') {
                    toggleCommentModal();
                } else if (action.toLowerCase() === 'ask') {
                    toggleQuestionModal();
                } else if (action.toLowerCase() === 'approveonce') {
                    toggleCommentModal();
                } else if (action.toLowerCase() === 'submitlimit') {
                    toggleLimitedApprovalModal();
                } else if (action.toLowerCase() === 'send') {
                    toggleSendModal();
                } else if (action.toLowerCase() === 'donotsend') {
                    toggleDoNotSendModal();
                }
            })
            .catch((err) =>
                toast.update(sendUserActionToast, {
                    render: 'Error sending user action',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                })
            )
            .finally(() => setLoadingUserAction(false));
    };

    const getUserActionLabelForHeader = (action = '') => {
        if (!action) return;
        if (action.toLowerCase() === 'approve') return 'Approved';
        if (action.toLowerCase() === 'deny') return 'Denied';
        if (action.toLowerCase() === 'approveonce') return 'Approved Once';
        else return action;
    };

    const { currentTime } = useContext(CurrentTimeContext);

    function difference2Parts(date) {
        const secs = Math.floor(Math.abs(currentTime - date) / 1000);
        const mins = Math.floor(secs / 60);
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        // const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;
        // const multiple = (term, n) => n !== 1 ? `${n} ${term}s` : `1 ${term}`;

        return {
            days: days,
            hours: hours % 24,
            hoursTotal: hours,
            minutesTotal: mins,
            minutes: mins % 60,
            seconds: secs % 60,
            secondsTotal: secs,
            //   milliSeconds: millisecs,
            //   get diffStr() {
            //     return `${multiple(`day`, this.days)}, ${
            //       multiple(`hour`, this.hours)}, ${
            //       multiple(`minute`, this.minutes)} and ${
            //       multiple(`second`, this.seconds)}`;
            //   },
            //   get diffStrMs() {
            //     return `${this.diffStr.replace(` and`, `, `)} and ${
            //       multiple(`millisecond`, this.milliSeconds)}`;
            //   },
        };
    }

    function showDifferenceInTime(date) {
        let difference = difference2Parts(date);
        if (difference.days) return difference.days > 1 ? difference.days + ' days ago' : difference.days + ' day ago';

        if (difference.hours)
            return difference.hours > 1 ? difference.hours + ' hours ago' : difference.hours + ' hour ago';

        if (difference.minutes)
            return difference.minutes > 1 ? difference.minutes + ' minutes ago' : difference.minutes + ' minute ago';

        return 'Just now';
    }

    const [commentModalInfo, setCommentModalInfo] = useState({
        isOpen: false,
        action: '',
    });

    const [questionModalInfo, setQuestionModalInfo] = useState({
        isOpen: false,
        action: '',
    });

    const [limitedApprovalModalInfo, setLimitedApprovalModalInfo] = useState({
        isOpen: false,
        action: '',
    });

    const [sendModalInfo, setSendModalInfo] = useState({
        isOpen: false,
        action: '',
    });

    const [doNotSendModalInfo, setDoNotSendModalInfo] = useState({
        isOpen: false,
        action: '',
    });

    function openCommentModal(action, color, label) {
        setCommentModalInfo({
            isOpen: true,
            action,
            color,
            label,
        });
    }

    function openSendModal(action, color, label) {
        setSendModalInfo({
            isOpen: true,
            action,
            color,
            label,
        });
    }

    function openDoNotSendModal(action, color, label) {
        setDoNotSendModalInfo({
            isOpen: true,
            action,
            color,
            label,
        });
    }

    function openQuestionModal(action, color, label) {
        setQuestionModalInfo({
            isOpen: true,
            action,
            color,
            label,
        });
    }

    function openLimitedApprovalModal(action, color, label) {
        setLimitedApprovalModalInfo({
            isOpen: true,
            action,
            color,
            label,
        });
    }

    function toggleCommentModal() {
        setCommentModalInfo({
            isOpen: !commentModalInfo.isOpen,
            action: '',
        });
    }

    function toggleSendModal() {
        setSendModalInfo({
            isOpen: !sendModalInfo.isOpen,
            action: '',
        });
    }

    function toggleDoNotSendModal() {
        setDoNotSendModalInfo({
            isOpen: !doNotSendModalInfo.isOpen,
            action: '',
        });
    }

    function toggleQuestionModal() {
        setQuestionModalInfo({
            isOpen: !questionModalInfo.isOpen,
            action: '',
        });
    }

    function toggleLimitedApprovalModal() {
        setLimitedApprovalModalInfo({
            isOpen: !limitedApprovalModalInfo.isOpen,
            action: '',
        });
    }

    const [showNonMediprocityUserAlert, setShowNonMediprocityUserAlert] = useState();

    const handleActionButtons = (action) => {
        if (!user.mediprocityUser) return setShowNonMediprocityUserAlert(true);
        // return toast.warning('SpecialtyRx Now credentials unavailable. Please add .', {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     theme: "light"
        //     })

        if (action.toLowerCase() === 'approve') {
            openCommentModal(action, 'success', 'Approve');
        } else if (action.toLowerCase() === 'deny') {
            openCommentModal(action, 'danger', 'Deny');
        } else if (action.toLowerCase() === 'ask') {
            openQuestionModal(action, 'warning', 'Discuss');
        } else if (action.toLowerCase() === 'approveonce') {
            openCommentModal(action, 'secondary', 'Approve Once');
        } else if (action.toLowerCase() === 'submitlimit') {
            openLimitedApprovalModal(action, 'dark', 'Limit Approval');
        } else if (action.toLowerCase() === 'send') {
            openSendModal(action, 'success', 'Send');
        } else if (action.toLowerCase() === 'donotsend') {
            openDoNotSendModal(action, 'danger', 'Do Not Send');
        }
    };

    const highCostActionButtons = [
        {
            icon: checkBold,
            title: 'Approve',
            action: 'approve',
            disable: false,
        },
        {
            icon: closeThick,
            title: 'Deny',
            action: 'deny',
            disable: false,
        },
        {
            icon: clockMinusOutline,
            title: 'Limit Days',
            action: 'submitlimit',
            disable: false,
        },
        {
            icon: numeric1CircleOutline,
            title: 'Approve Once',
            action: 'approveonce',
            disable: false,
        },
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const highCostAlertsActionButtons = [
        {
            icon: checkBold,
            title: 'Approve',
            action: 'approve',
            disable: false,
        },
        {
            icon: closeThick,
            title: 'Deny',
            action: 'deny',
            disable: false,
        },
        {
            icon: clockMinusOutline,
            title: 'Limit Days',
            action: 'submitlimit',
            disable: false,
        },
        // {
        //     icon: numeric1CircleOutline,
        //     title: 'Approve Once',
        //     action: 'approveonce',
        //     disable: false,
        // },
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const clarificationsActionButtons = [
        {
            icon: checkBold,
            title: 'Send',
            action: 'Send',
            disable: false,
        },
        {
            icon: closeThick,
            title: 'Do Not Send',
            action: 'DoNotSend',
            disable: false,
        },
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const remainingWidgetsActionButtons = [
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const archivedActionButtons = [
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const admissionMedicationReviewActionButtons = [
        {
            icon: helpCircleOutline,
            title: 'Discuss',
            action: 'ask',
            disable: false,
        },
    ];

    const [firstTimeScrolling, setFirstTimeScrolling] = useState(true);

    useEffect(() => {
        if (activeTab === 'actions' && firstTimeScrolling) {
            setFirstTimeScrolling(false);
            let container = document.querySelector('.action-display-simplebar-container .simplebar-content-wrapper');
            container && container.scrollTo({ top: container.scrollHeight, behavior: 'auto' });
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'actions' && !firstTimeScrolling) {
            let container = document.querySelector('.action-display-simplebar-container .simplebar-content-wrapper');
            container && container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' });
        }
    }, [alertInfo]);

    const { updateReadStatus, updateArchivedReadStatus } = useUpdateAlert();

    const changeReadStatus = async (status) => {
        function returnUrl() {
            if (category === 'highCost') {
                return '/highcost_readByFaci';
            } else if (category === 'highcostalerts') {
                return '/highcostalerts_readByFaci';
            } else if (category === 'clarifications') {
                return '/openbatch_readByFaci';
            } else if (category === 'priorAuth') {
                return '/priorauth_readByFaci';
            } else if (category === 'nonCovered') {
                return '/noncovered_readByFaci';
            } else if (category === 'therapeuticInt') {
                return '/therapeuticint_readByFaci';
            } else if (category === 'admissionMeds') {
                return '/admissionmeds_readByFaci';
            } else if (category === 'patMissing') {
                return '/patmissing_readByFaci';
            }
        }
        let id;
        if (category === 'admissionMeds') {
            id = alert.admissionMedsGroupID;
        } else {
            id = alert._internalID;
        }

        SrxApi.post(returnUrl(), {
            _internalID: id,
            readByFaci: status,
        })
            .then((res) => {
                tab === 'archive'
                    ? updateArchivedReadStatus(status, alert, category)
                    : updateReadStatus(status, alert, category);
            })
            .catch((err) => console.log(err.message));
    };

    useEffect(() => {
        if (!alert.readByFaci && user.userType !== 'PharmacyUser' && checkFacilityPermissionAutomatically())
            changeReadStatus(true);
    }, []);

    const checkFacilityPermissionManually = () => {
        let facilityId = alert.facID;
        let facilityList = facilities;
        return facilityList.find((facility) => {
            if (facilityId === facility.facID) {
                return (
                    facility.permissions.widget_markAlertReadManually &&
                    facility.permissions.widget_markAlertUnreadManually
                );
            }
        });
    };

    const checkFacilityPermissionAutomatically = () => {
        let facilityId = alert.facID;
        let facilityList = facilities;
        return facilityList.find((facility) => {
            if (facilityId === facility.facID) {
                return (
                    facility.permissions.widget_markAlertReadAutomatically &&
                    facility.permissions.widget_markAlertUnreadAutomatically
                );
            }
        });
    };

    function getLastAlertStage(alert) {
        const stages = ['entered', 'initReview', 'labelPrintedOn', 'packed', 'checked', 'placedInTote'];

        let lastStage = null;
        for (let i = 0; i < stages.length; i++) {
            const stage = stages[i];
            if (alert[stage] !== null) {
                lastStage = stage;
            } else {
                break;
            }
        }

        if (lastStage !== null) {
            switch (lastStage) {
                case 'entered':
                    return 'Entered';
                case 'initReview':
                    return 'Initial Review';
                case 'labelPrintedOn':
                    return 'Label Printed On';
                case 'packed':
                    return 'Packed';
                case 'checked':
                    return 'Checked';
                case 'placedInTote':
                    return 'Placed In Tote';
            }
        } else {
            return null;
        }
    }
    function checkAlertStatus(alert) {
        if (alert.posted || alert.deleted || alert.profileOnly === true) {
            if (alert.posted) {
                return 'POSTED';
            } else if (alert.deleted) {
                return 'DELETED';
            } else if (alert.profileOnly) {
                return 'PROFILED';
            }
        } else {
            return null;
        }
    }
    const checkQuantity = (alert) => {
        if (alert.archiveDtTm) {
            if (alert.qty !== alert.orgQty) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const checkSupply = (alert) => {
        if (alert.archiveDtTm) {
            if (alert.daysSupply !== alert.orgDaysSupply) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader
                toggle={toggle}
                className="gradient-modal d-flex"
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {/* <Badge
                            style={{ fontSize: '16px' }}
                            className='badge badge-secondary py-1'
                            >
                            {name.split(" ").map((word) => { 
                                return word[0].toUpperCase() + word.substring(1); 
                            }).join(" ")}
                        </Badge> */}
                {name
                    .split(' ')
                    .map((word) => {
                        return word[0].toUpperCase() + word.substring(1);
                    })
                    .join(' ')}
                {stateName === 'highCostList' && (
                    <Badge color={'secondary'} className="ml-1">
                        {getUserActionLabelForHeader(alert.srxStatus)}
                    </Badge>
                )}
                {
                    <Button
                        title={alert.readByFaci ? 'Mark as unread' : 'Mark as read'}
                        hidden={!checkFacilityPermissionManually()}
                        onClick={() => {
                            checkFacilityPermissionManually();
                            changeReadStatus(!alert.readByFaci);
                            if (alert.readByFaci) toggle();
                        }}
                        className="link-button p-0 mb-1 ml-1"
                        color="link"
                        style={{ color: 'white', fontSize: '26px' }}>
                        <Icon icon={alert.readByFaci ? emailOutline : emailOpenOutline} />
                    </Button>
                }
                {alert?.rxNo !== undefined || alert?.admissionMedsGroupID !== undefined ? (
                    <>
                        <Link
                            // to={tableRowDetailPagelink(category)}
                            onClick={() => {
                                navigator.clipboard.writeText(`${tableRowDetailPagelink(category)}`);
                                toast.info('URL copied to clipboard');
                            }}
                            className="link-button p-0 mb-1 ml-1"
                            color="link"
                            style={{ color: 'white', fontSize: '26px' }}
                        >
                            <Icon icon="mdi:link-variant" style={{ color: 'white', fontSize: '26px' }} />
                        </Link>
                    </>
                ) : null}

            </ModalHeader>
            <ModalBody>
                <div style={{ backgroundColor: 'none' }}>
                    <Nav tabs className="nav-justified nav-bordered mb-2">
                        <NavItem>
                            <NavLink
                                style={{ backgroundColor: 'transparent' }}
                                href="#"
                                className={classNames({ active: activeTab === 'detail' }, 'pt-0')}
                                onClick={() => {
                                    if (activeTab === 'actions') setActiveTab('detail');
                                }}>
                                <span>Detail</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ backgroundColor: 'transparent' }}
                                href="#"
                                className={classNames({ active: activeTab === 'actions' }, 'pt-0')}
                                onClick={() => {
                                    if (activeTab === 'detail') setActiveTab('actions');
                                }}>
                                <span>
                                    Actions/Discussions{' '}
                                    <Badge color="danger" className="ml-1">
                                        {alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}
                                    </Badge>
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                {stateName === 'highCostList' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p>RX Number: </p>
                                        <span>
                                            <b>{alert.rxNo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <p>Dispense Date:</p>
                                        <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                    </div>
                                    <div >
                                        <p>Alerted On:</p>
                                        <span >
                                            <b>{getDateTime(alert.dateCreated)}</b>
                                        </span>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <div>
                                    <span>
                                        Nursing Station: <b>{alert.nsid}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Room: <b>{alert.room}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Bed: <b>{alert.bed}</b>
                                    </span>
                                </div>
                                <hr />
                                <div>
                                    Drug Label Name: <b>{alert.drugLabelName}</b>
                                </div>
                                <div>
                                    Qty: <b>{alert.qty}</b>
                                </div>
                                <div>
                                    Days Supply: <b>{alert.daysSupply}</b>
                                </div>
                                <div>
                                    Total Price: <b>${alert.ttlPrice}</b>
                                </div>
                                {(alert.srxStatus || alert.pharmacyStatus) && <hr />}
                                {alert.srxStatus && (
                                    <h4 className="my-1">Resolution: {getUserActionLabelForHeader(alert.srxStatus)}</h4>
                                )}
                                {alert.pharmacyStatus && (
                                    <small>
                                        Pharmacy Resolution: <b>{alert.pharmacyStatus}</b>
                                    </small>
                                )}
                                <hr />
                                {/* <h4>Actions</h4> */}
                                {showNonMediprocityUserAlert && (
                                    <Alert color={'danger'} className="alert-link">
                                        SpecialtyRx Now credentials are required for user actions <br /> <br />
                                        <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                    </Alert>
                                )}
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID')
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            highCostActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <ActionMessage
                                                                        action={action}
                                                                        i={i}
                                                                        alert={alert}
                                                                        category={category}
                                                                    />
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end"
                                                style={{
                                                    height: '45px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }}>
                                                {(alert.hasOwnProperty('_internalArchiveID')
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        highCostActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionLimitedApprovalModal
                                                    isOpen={limitedApprovalModalInfo.isOpen}
                                                    action={limitedApprovalModalInfo.action}
                                                    toggle={toggleLimitedApprovalModal}
                                                    color={limitedApprovalModalInfo.color}
                                                    label={limitedApprovalModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'HighCost'}
                                                />
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'HighCost'}
                                                />
                                                <ActionCommentModal
                                                    isOpen={commentModalInfo.isOpen}
                                                    action={commentModalInfo.action}
                                                    toggle={toggleCommentModal}
                                                    color={commentModalInfo.color}
                                                    label={commentModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'HighCost'}
                                                />
                                            </div>
                                            {/* <Row>
                                    <Col>
                                            <Row>
                                                <Col className="mb-2 mb-sm-0">
                                                    <FormGroup>
                                                    <Label for="newMessage">Comment</Label>
                                                    <Input
                                                        value={actionComment}
                                                        onChange={(e) => setActionComment(e.target.value)}
                                                        type="text"
                                                        name="newMessage"
                                                        placeholder='Comment...'
                                                    ></Input>
                                                    </FormGroup>
                                                </Col> */}
                                            {/* <Col className="col-sm-auto">
                                                    <div className="btn-group">
                                                        <Link to="#" className="btn btn-light">
                                                            <i className="uil uil-paperclip"></i>
                                                        </Link>
                                                        <Link to="#" className="btn btn-light">
                                                            {' '}
                                                            <i className="uil uil-smile"></i>{' '}
                                                        </Link>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success chat-send btn-block">
                                                            <i className="uil uil-message"></i>
                                                        </button>
                                                    </div>
                                                </Col> */}
                                            {/* </Row>
                                            <Row>
                                                <Col className='d-flex justify-content-end mt-2'>
                                                <Button color="warning" className='mr-3'
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('ask', 'HighCost', alert._internalID)}
                                                    >
                                                        Ask
                                                    </Button>{' '}
                                                    <Button color="success" className='mr-3'
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('approve', 'HighCost', alert._internalID)}
                                                    >
                                                        Approve
                                                    </Button>{' '}
                                                    <Button 
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('approveOnce', 'HighCost', alert._internalID)}
                                                    color="secondary" size='sm' style={{ whiteSpace: 'nowrap' }} className="mr-3">
                                                        Approve Once
                                                    </Button>
                                                    <Button color="danger"
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('deny', 'HighCost', alert._internalID)}
                                                    >
                                                        Deny
                                                    </Button>  
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row> */}
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                        {/* <Row>
                    <Col>
                        <Media className="mt-3">
                            <img className="mr-2 rounded" src={'https://picsum.photos/50'} alt="" height="32" />
                            <Media body>
                                <h5 className="m-0">{'name'} </h5>
                                <p className="text-muted mb-0">
                                    <small>{'posted on'}</small>
                                </p>

                                <p className="my-1">'content'</p>

                            </Media>
                        </Media>
                    </Col>
                </Row> */}
                        {/* <Row className='mb-3 mt-'>
                    <Col className='d-flex justify-content-start'>
                    <div>
                    <Button color="secondary" size='sm' style={{ whiteSpace: 'nowrap' }} className="mr-2">
                        Ask
                    </Button>
                    </div>
                    <div>
                    <Button color="secondary" size='sm' style={{ whiteSpace: 'nowrap' }}>
                        Approve Once
                    </Button>
                    </div>
                    <div className='ml-auto'>
                    <InputGroup>
                    <div style={{ width: '150px' }}>
                        <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            styles={customSelectStyles}
                            // onChange={(e) => onPendingFaciRoleChange(e.value, facility)}
                            // hideSelectedOptions
                            // value={facility.isAdmin ? 
                            //     {value: 1, label: 'Admin'} 
                            //     : 
                            //     {value: 0, label: 'User'}
                            // }
                            // formatOptionLabel={formatOptionLabel}
                            options={
                                [
                                    {value: 0, label: "Limit to 3 days"},
                                    {value: 1, label: "Limit to 5 days"},
                                    {value: 2, label: "Limit to 7 days"}
                                ]
                        }></Select>
                        </div>
                        <InputGroupAddon addonType="append">
                            <Button color="secondary" size='sm'>Submit Limit</Button>
                        </InputGroupAddon>
                    </InputGroup>
                    </div>
                </Col>
                </Row> */}
                    </>
                )}
                {stateName === 'highCostAlertsListFlat' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p>RX Number: </p>
                                        <span>
                                            <b>{alert.rxNo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <p>Dispense Date:</p>
                                        <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                    </div>
                                    <div >
                                        <p>Alerted On:</p>
                                        <span >
                                            <b>{getDateTime(alert.dateCreated)}</b>
                                        </span>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <div>
                                    <span>
                                        Nursing Station: <b>{alert.nsid}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Room: <b>{alert.room}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Bed: <b>{alert.bed}</b>
                                    </span>
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between">
                                    <span>
                                        Drug Label Name: <b>{alert.drugLabelName}</b>
                                    </span>
                                    <div>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            Stage:
                                            <Badge color="dark" className="ml-1 " style={{ fontSize: '14px' }}>
                                                {getLastAlertStage(alert)}
                                            </Badge>
                                        </div>
                                    </div>
                                    <span className="ml-2">
                                        <Badge color="secondary" className="ml-1" style={{ fontSize: '14px' }}>
                                            {checkAlertStatus(alert)}
                                        </Badge>
                                    </span>
                                </div>
                                <div>
                                    Dispense Date: <b>{getDateTime(alert.dispenseDt)}</b>
                                </div>
                                <div>
                                    RX Number: <b>{alert.rxNo}</b>
                                </div>
                                <div className="d-flex justify-content-between">
                                    {!checkQuantity(alert) && <div>
                                        Qty: <b>{alert.orgQty}</b>
                                    </div>}
                                    {checkQuantity(alert) && (<div>
                                        Qty: <b>{alert.qty}</b>
                                    </div>)}
                                    {checkQuantity(alert) && (
                                        <div>
                                            Original Quantity: <b>{alert.orgQty}</b>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        Days Supply: <b>{alert.daysSupply}</b>
                                    </div>
                                    {checkSupply(alert) && (
                                        <div>
                                            Original Days Supply: <b>{alert.orgDaysSupply}</b>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    Total Price: <b>${alert.ttlPrice}</b>
                                </div>

                                {(alert.srxStatus || alert.pharmacyStatus) && <hr />}
                                {alert.srxStatus && (
                                    <h4 className="my-1">Resolution: {getUserActionLabelForHeader(alert.srxStatus)}</h4>
                                )}
                                {alert.pharmacyStatus && (
                                    <small>
                                        Pharmacy Resolution: <b>{alert.pharmacyStatus}</b>
                                    </small>
                                )}
                                <hr />
                                {/* <h4>Actions</h4> */}
                                {showNonMediprocityUserAlert && (
                                    <Alert color={'danger'} className="alert-link">
                                        SpecialtyRx Now credentials are required for user actions <br /> <br />
                                        <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                    </Alert>
                                )}
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID') && alert._internalArchiveID !== 0
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            'highCostAlert',
                                            pharmacies,
                                            alert.pharmID,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            highCostAlertsActionButtons,
                                            'highCostAlert',
                                            pharmacies,
                                            alert.pharmID,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <ActionMessage
                                                                        action={action}
                                                                        i={i}
                                                                        alert={alert}
                                                                        category={category}
                                                                    />
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end"
                                                style={{
                                                    height: '45px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }}>
                                                {(alert.hasOwnProperty('_internalArchiveID') && alert._internalArchiveID !== 0
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        'highCostAlert',
                                                        pharmacies,
                                                        alert.pharmID,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        highCostAlertsActionButtons,
                                                        'highCostAlert',
                                                        pharmacies,
                                                        alert.pharmID,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionLimitedApprovalModal
                                                    isOpen={limitedApprovalModalInfo.isOpen}
                                                    action={limitedApprovalModalInfo.action}
                                                    toggle={toggleLimitedApprovalModal}
                                                    color={limitedApprovalModalInfo.color}
                                                    label={limitedApprovalModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'highcostalerts'}
                                                />
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'highcostalerts'}
                                                />
                                                <ActionCommentModal
                                                    isOpen={commentModalInfo.isOpen}
                                                    action={commentModalInfo.action}
                                                    toggle={toggleCommentModal}
                                                    color={commentModalInfo.color}
                                                    label={commentModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'highcostalerts'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                    </>
                )}
                {stateName === 'nonCoveredList' && (
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={'detail'}>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p>RX Number: </p>
                                    <span>
                                        <b>{alert.rxNo}</b>
                                    </span>
                                </div>
                                <div>
                                    <p>Dispense Date:</p>
                                    <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                </div>
                                <div >
                                    <p>Alerted On:</p>
                                    <span >
                                        <b>{getDateTime(alert.dateCreated)}</b>
                                    </span>
                                    <span className="ml-2">
                                        <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div>
                                Facility Name:{' '}
                                <b>
                                    ({alert.facID}) {alert.facName}
                                </b>
                            </div>
                            <div>
                                Patient Name: <b>{alert.patName}</b>
                            </div>
                            <div>
                                <span>
                                    Nursing Station: <b>{alert.nsid}</b>
                                </span>{' '}
                                <span className="ml-2">
                                    Room: <b>{alert.room}</b>
                                </span>{' '}
                                <span className="ml-2">
                                    Bed: <b>{alert.bed}</b>
                                </span>
                            </div>
                            <hr />
                            <div>
                                Drug Label Name: <b>{alert.drugLabelName}</b>
                            </div>
                            <div>
                                Qty: <b>{alert.qty}</b>
                            </div>
                            <div>
                                Days Supply: <b>{alert.daysSupply}</b>
                            </div>
                            <div>
                                Total Price: <b>${alert.ttlPrice}</b>
                            </div>
                            <hr />
                            <div>
                                Comments:{' '}
                                {alert.nonCoveredCommentList.map((comment, i) => (
                                    <div>
                                        {i > 0 && i !== alert.nonCoveredCommentList.length && (
                                            <hr className="mx-0 p-0 w-25" />
                                        )}
                                        <div>
                                            <small>
                                                <i>{getDateAndTime(comment.loggedDateTime)}</i>
                                            </small>
                                        </div>
                                        <div>
                                            <b>{comment.comment}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <hr />
                            {showNonMediprocityUserAlert && (
                                <Alert color={'danger'} className="alert-link">
                                    SpecialtyRx Now credentials are required for user actions <br /> <br />
                                    <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                </Alert>
                            )}
                            <div
                                className="bg-dark d-flex justify-content-end shadow"
                                style={{ height: '45px', borderRadius: '3px' }}>
                                {(alert.hasOwnProperty('_internalArchiveID')
                                    ? checkActionPermissions(
                                        archivedActionButtons,
                                        requestActionPermissionWidgetName,
                                        pharmacies,
                                        alert.pharmId,
                                        alert.facID
                                    )
                                    : checkActionPermissions(
                                        remainingWidgetsActionButtons,
                                        requestActionPermissionWidgetName,
                                        pharmacies,
                                        alert.pharmId,
                                        alert.facID
                                    )
                                ).map((button, i) => (
                                    <ActionButton
                                        handleOnClick={handleActionButtons}
                                        icon={button.icon}
                                        title={button.title}
                                        index={i + 'detail'}
                                        action={button.action}
                                        disabled={button.disable}
                                    />
                                ))}
                            </div>
                        </TabPane>
                        <TabPane tabId={'actions'}>
                            {
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-end align-items-center mb-1">
                                            {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                        </div>
                                        {showNonMediprocityUserAlert && (
                                            <Alert color={'danger'}>
                                                <b>
                                                    SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                    <br />
                                                    <a href="/settings/specialtyRxNow">
                                                        Set SpecialtyRx Now credentials
                                                    </a>
                                                </b>
                                            </Alert>
                                        )}
                                        <div
                                            style={{
                                                border: '1px solid #D6E0EC',
                                                borderTopLeftRadius: '3px',
                                                borderTopRightRadius: '3px',
                                                minHeight: '400px',
                                                maxHeight: '400px',
                                            }}
                                            className="shadow-sm action-display-simplebar-container">
                                            {alert.hasOwnProperty('requestedActions') &&
                                                alert.requestedActions?.length ? (
                                                <SimpleBar
                                                    style={{ maxHeight: '398px', minHeight: '398px', width: '100%' }}>
                                                    <ul className="conversation-list mt-2">
                                                        {alert.requestedActions
                                                            .sort((a, b) =>
                                                                new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                            )
                                                            .map((action, i) => (
                                                                // <div>
                                                                //     <p>{action.action}</p>
                                                                //     <p>{action.status}</p>
                                                                //     <p>{action.sentTime}</p>
                                                                // </div>
                                                                <ActionMessage
                                                                    action={action}
                                                                    i={i}
                                                                    alert={alert}
                                                                    category={category}
                                                                />
                                                            ))}
                                                    </ul>
                                                </SimpleBar>
                                            ) : (
                                                <h5 className="text-center mt-3">
                                                    <i>No recorded actions</i>
                                                </h5>
                                            )}
                                        </div>
                                        <div
                                            className="bg-dark d-flex justify-content-end"
                                            style={{
                                                height: '45px',
                                                borderBottomLeftRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                            }}>
                                            {(alert.hasOwnProperty('_internalArchiveID')
                                                ? checkActionPermissions(
                                                    archivedActionButtons,
                                                    requestActionPermissionWidgetName,
                                                    pharmacies,
                                                    alert.pharmId,
                                                    alert.facID
                                                )
                                                : checkActionPermissions(
                                                    remainingWidgetsActionButtons,
                                                    requestActionPermissionWidgetName,
                                                    pharmacies,
                                                    alert.pharmId,
                                                    alert.facID
                                                )
                                            ).map((button, i) => (
                                                <ActionButton
                                                    handleOnClick={handleActionButtons}
                                                    icon={button.icon}
                                                    title={button.title}
                                                    index={i}
                                                    action={button.action}
                                                    disabled={button.disable}
                                                />
                                            ))}
                                            <ActionQuestionModal
                                                isOpen={questionModalInfo.isOpen}
                                                action={questionModalInfo.action}
                                                toggle={toggleQuestionModal}
                                                color={questionModalInfo.color}
                                                label={questionModalInfo.label}
                                                submitAction={handleUserAction}
                                                loading={loadingUserAction}
                                                widget={'NonCovered'}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </TabPane>
                    </TabContent>
                )}
                {stateName === 'priorAuthList' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p>RX Number: </p>
                                        <span>
                                            <b>{alert.rxNo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <p>Dispense Date:</p>
                                        <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                    </div>
                                    <div >
                                        <p>Alerted On:</p>
                                        <span >
                                            <b>{getDateTime(alert.dateCreated)}</b>
                                        </span>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <hr />
                                <div>
                                    Drug Label Name: <b>{alert.drugLabelName}</b>
                                </div>
                                <div>
                                    Qty: <b>{alert.qty}</b>
                                </div>
                                <div>
                                    Total Price: <b>${alert.ttlPrice}</b>
                                </div>
                                <hr />
                                <div className="d-flex">
                                    Status:
                                    <Badge
                                        pill
                                        // color={alert.srxStatus.toLowerCase() === 'pending' ? 'danger' : 'success'}
                                        // className="ml-1 px-2 pt-1"
                                        style={{ fontSize: '10px', paddingTop: '5px' }}
                                        className={`ml-1 badge badge-outline-${alert.srxStatus.toLowerCase() === 'pending' ? 'danger' : 'success'
                                            }`}>
                                        {capitalizeFirstLetter(alert.srxStatus.toLowerCase())}
                                    </Badge>
                                </div>
                                <div>
                                    Billing Comment: <br /> <b>{alert.billingComment}</b>
                                </div>
                                <hr />
                                {showNonMediprocityUserAlert && (
                                    <Alert color={'danger'} className="alert-link">
                                        SpecialtyRx Now credentials are required for user actions <br /> <br />
                                        <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                    </Alert>
                                )}
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID')
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            remainingWidgetsActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <ActionMessage
                                                                        action={action}
                                                                        i={i}
                                                                        alert={alert}
                                                                        category={category}
                                                                    />
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end"
                                                style={{
                                                    height: '45px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }}>
                                                {(alert.hasOwnProperty('_internalArchiveID')
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        remainingWidgetsActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'PriorAuth'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                    </>
                )}
                {stateName === 'therapeuticInterchangeList' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p>RX Number: </p>
                                        <span>
                                            <b>{alert.rxNo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <p>Dispense Date:</p>
                                        <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                    </div>
                                    <div >
                                        <p>Alerted On:</p>
                                        <span >
                                            <b>{getDateTime(alert.dateCreated)}</b>
                                        </span>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <div>
                                    <span>
                                        Nursing Station: <b>{alert.nsid}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Room: <b>{alert.room}</b>
                                    </span>
                                </div>
                                <hr />
                                <div>
                                    Prescribed Drug: <b>{alert.prescribedDrugName}</b>
                                </div>
                                <div>
                                    Drug Dispensed: <b>{alert.drugLabelName}</b>
                                </div>
                                <div>
                                    Qty: <b>{alert.qty}</b>
                                </div>
                                <div>
                                    Days Supply: <b>{alert.daysSupply}</b>
                                </div>
                                <div>
                                    Total Price: <b>${alert.ttlPrice}</b>
                                </div>
                                <hr />
                                {showNonMediprocityUserAlert && (
                                    <Alert color={'danger'} className="alert-link">
                                        SpecialtyRx Now credentials are required for user actions <br /> <br />
                                        <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                    </Alert>
                                )}
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID')
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            remainingWidgetsActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <ActionMessage
                                                                        action={action}
                                                                        i={i}
                                                                        alert={alert}
                                                                        category={category}
                                                                    />
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end"
                                                style={{
                                                    height: '45px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }}>
                                                {(alert.hasOwnProperty('_internalArchiveID')
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        remainingWidgetsActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'TherapeuticInt'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                    </>
                )}
                {stateName === 'admissionMedsList' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-end align-items-center">
                                    {/* <div>
                    RX Number: <b>{alert.rxNo}</b>
                </div> */}
                                    <div className="mr-auto">
                                        Admission Date: <b>{getDateTime(alert.admDate)}</b>
                                    </div>
                                    {/* <div>
                                        Alerted On: <b>{getDateTime(alert.dateCreated)}</b>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div> */}
                                    <div>
                                        <Button
                                            className="link-button p-0"
                                            color="link"
                                            style={{ color: '#6c757d', fontSize: '26px' }}
                                            title="Print PDF"
                                            onClick={() => {
                                                let doc = generatePDF(alert);
                                                doc.autoPrint();
                                                window.open(doc.output('bloburl'), '_blank');
                                            }}>
                                            <Icon icon={printerIcon} />
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            className="link-button p-0"
                                            color="link"
                                            style={{ color: '#6c757d', fontSize: '26px' }}
                                            title="Download PDF"
                                            onClick={() => {
                                                let doc = generatePDF(alert);
                                                doc.save(
                                                    'admission-' +
                                                    generateFileName(
                                                        `${alert.patName}-${new Date(
                                                            alert.admDate
                                                        ).toLocaleDateString()}`
                                                    )
                                                );
                                            }}>
                                            <Icon icon={downloadIcon} />
                                        </Button>
                                    </div>
                                </div>
                                <hr style={{ marginTop: '10px' }} />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <div>
                                    <span>
                                        Nursing Station: <b>{alert.nsid}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Room: <b>{alert.room}</b>
                                    </span>{' '}
                                    <span className="ml-2">
                                        Bed: <b>{alert.bed}</b>
                                    </span>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-end">
                                    <h4 className="ml-1">Admission Meds:</h4>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="mr-1">
                                            Total Meds: <b>{alert.admissionMedsDetailsList.length}</b>
                                        </div>
                                        <div className="mr-1">
                                            Grand Total: $<b>{alert.sumTtlPrice}</b>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #eef2f7', borderRadius: '5px' }}>
                                    {/* <hr className='w-50 ml-0' /> */}
                                    <SimpleBar style={{ maxHeight: '330px' }} className="mx-1 px-1">
                                        {alert.admissionMedsDetailsList.map((line, i) => (
                                            <>
                                                {/* <Card className={classNames('mx-1', 'border', 'mb-1', 'shadow-sm', { 'mt-1': i === 0 })}>
                        <CardHeader className='bg-light'>RX Number: <b>{line.rxNo}</b></CardHeader>
                        <CardBody>
                        <div>Drug: <b>{line.drug}</b></div>
                        <div>Days Supply: <b>{line.daysSupply}</b></div>
                        <div>Qty: <b>{line.qty}</b></div>
                        <div>Total Price: <b>${line.ttlPrice}</b></div>
                        </CardBody>
                    </Card> */}
                                                <Card className="mb-0">
                                                    <CardHeader
                                                        id={'medList' + i}
                                                        className="bg-light admissionMedsAccordion"
                                                        style={{ padding: '6px 12px 0px 12px' }}>
                                                        <span>
                                                            <b>{line.drug}</b>
                                                        </span>
                                                        <span className="float-right">
                                                            <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                                        </span>
                                                    </CardHeader>
                                                    <UncontrolledCollapse toggler={'medList' + i}>
                                                        <CardBody>
                                                            <div>
                                                                Drug: <b>{line.drug}</b>
                                                            </div>
                                                            <div>
                                                                RX Number: <b>{line.rxNo}</b>
                                                            </div>
                                                            <div>
                                                                Dispense Date: <b>{getDateTime(line.dispenseDt)}</b>
                                                            </div>
                                                            <div>
                                                                Alerted On: <b>{getDateTime(alert.admDate)}</b>
                                                                {/* <span className="ml-2">
                                                                    <b>{getDateAndTimeStamp(alert.admDate)}</b>
                                                                </span> */}
                                                            </div>
                                                            <div>
                                                                Days Supply: <b>{line.daysSupply}</b>
                                                            </div>
                                                            <div>
                                                                Qty: <b>{line.qty}</b>
                                                            </div>
                                                            <div>
                                                                Total Price: <b>${line.ttlPrice}</b>
                                                            </div>
                                                        </CardBody>
                                                    </UncontrolledCollapse>
                                                </Card>
                                                {/* {(i !== alert.admisionMedsDetailsList.length - 1) && <hr className='w-50 ml-0'/>} */}
                                            </>
                                        ))}
                                    </SimpleBar>
                                </div>
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID')
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            admissionMedicationReviewActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {' '}
                                                {console.log(alert.hasOwnProperty('requestedActions'))}
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <>
                                                                        <ActionMessage
                                                                            action={action}
                                                                            i={i}
                                                                            alert={alert}
                                                                            category={category}
                                                                        />
                                                                    </>
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end shadow"
                                                style={{ height: '45px', borderRadius: '3px' }}>
                                                {(alert.hasOwnProperty('_internalArchiveID')
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        admissionMedicationReviewActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i + 'detail'}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'AdmissionMeds'}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                    </>
                )}
                {stateName === 'openBatchList' && (
                    <>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={'detail'}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p>RX Number: </p>
                                        <span>
                                            <b>{alert.rxNo}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <p>Dispense Date:</p>
                                        <span><b>{getDateTime(alert.dispenseDt)}</b></span>
                                    </div>
                                    <div >
                                        <p>Alerted On:</p>
                                        <span >
                                            <b>{getDateTime(alert.dateCreated)}</b>
                                        </span>
                                        <span className="ml-2">
                                            <b>{getDateAndTimeStamp(alert.dateCreated)}</b>
                                        </span>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    Facility Name:{' '}
                                    <b>
                                        ({alert.facID}) {alert.facName}
                                    </b>
                                </div>
                                <div>
                                    Patient Name: <b>{alert.patName}</b>
                                </div>
                                <div>
                                    <span>
                                        Nursing Station: <b>{alert.nsid}</b>
                                    </span>
                                </div>
                                <hr />
                                <div>
                                    Drug: <b>{alert.drugLabelName}</b>
                                </div>
                                <div>
                                    Qty: <b>{alert.qty}</b>
                                </div>
                                <div>
                                    Days Supply: <b>{alert.daysSupply}</b>
                                </div>
                                <div>
                                    Sig: <b>{decode(alert.sig)}</b>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="ml-1">Comments:</h4>
                                    <div className="mr-1">
                                        Total: <b>{alert.openBatchCommentList.length}</b>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #eef2f7', borderRadius: '5px' }}>
                                    {/* <hr className='w-50 ml-0' /> */}
                                    <SimpleBar style={{ maxHeight: '330px' }} className="mx-1 px-1">
                                        {alert.openBatchCommentList.map((comment, i) => (
                                            <>
                                                <Card
                                                    className={classNames('mx-1', 'border', 'mb-1', 'shadow-sm', {
                                                        'mt-1': i === 0,
                                                    })}>
                                                    <CardHeader className="bg-light">
                                                        <div className="mb-1">
                                                            <b>{comment.qerDescription}</b>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                <i>{getDateAndTime(comment.loggedDateTime)}</i>
                                                            </small>
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div>{comment.comment}</div>
                                                    </CardBody>
                                                </Card>
                                                {/* {(i !== alert.admisionMedsDetailsList.length - 1) && <hr className='w-50 ml-0'/>} */}
                                            </>
                                        ))}
                                    </SimpleBar>
                                </div>
                                {(alert.srxStatus || alert.pharmacyStatus) && <hr />}
                                {alert.srxStatus && (
                                    <h4 className="my-1">Resolution: {getUserActionLabelForHeader(alert.srxStatus)}</h4>
                                )}
                                {alert.pharmacyStatus && (
                                    <small>
                                        Pharmacy Resolution: <b>{alert.pharmacyStatus}</b>
                                    </small>
                                )}
                                <hr />
                                {/* <h4>Actions</h4> */}
                                {showNonMediprocityUserAlert && (
                                    <Alert color={'danger'} className="alert-link">
                                        SpecialtyRx Now credentials are required for user actions <br /> <br />
                                        <a href="/settings/specialtyRxNow">Set SpecialtyRx Now credentials</a>
                                    </Alert>
                                )}
                                <div
                                    className="bg-dark d-flex justify-content-end shadow"
                                    style={{ height: '45px', borderRadius: '3px' }}>
                                    {(alert.hasOwnProperty('_internalArchiveID')
                                        ? checkActionPermissions(
                                            archivedActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                        : checkActionPermissions(
                                            clarificationsActionButtons,
                                            requestActionPermissionWidgetName,
                                            pharmacies,
                                            alert.pharmId,
                                            alert.facID
                                        )
                                    ).map((button, i) => (
                                        <ActionButton
                                            handleOnClick={handleActionButtons}
                                            icon={button.icon}
                                            title={button.title}
                                            index={i + 'detail'}
                                            action={button.action}
                                            disabled={button.disable}
                                        />
                                    ))}
                                </div>
                            </TabPane>
                            <TabPane tabId={'actions'}>
                                {
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end align-items-center mb-1">
                                                {/* <div>Total Actions: <b>{alert.hasOwnProperty('requestedActions') ? alert.requestedActions?.length : 0}</b></div> */}
                                            </div>
                                            {showNonMediprocityUserAlert && (
                                                <Alert color={'danger'}>
                                                    <b>
                                                        SpecialtyRx Now credentials are required for user actions <br />{' '}
                                                        <br />
                                                        <a href="/settings/specialtyRxNow">
                                                            Set SpecialtyRx Now credentials
                                                        </a>
                                                    </b>
                                                </Alert>
                                            )}
                                            <div
                                                style={{
                                                    border: '1px solid #D6E0EC',
                                                    borderTopLeftRadius: '3px',
                                                    borderTopRightRadius: '3px',
                                                    minHeight: '400px',
                                                    maxHeight: '400px',
                                                }}
                                                className="shadow-sm action-display-simplebar-container">
                                                {alert.hasOwnProperty('requestedActions') &&
                                                    alert.requestedActions?.length ? (
                                                    <SimpleBar
                                                        style={{
                                                            maxHeight: '398px',
                                                            minHeight: '398px',
                                                            width: '100%',
                                                        }}>
                                                        <ul className="conversation-list mt-2">
                                                            {alert.requestedActions
                                                                .sort((a, b) =>
                                                                    new Date(a.sentTime) < new Date(b.sentTime) ? -1 : 1
                                                                )
                                                                .map((action, i) => (
                                                                    // <div>
                                                                    //     <p>{action.action}</p>
                                                                    //     <p>{action.status}</p>
                                                                    //     <p>{action.sentTime}</p>
                                                                    // </div>
                                                                    <ActionMessage
                                                                        action={action}
                                                                        i={i}
                                                                        alert={alert}
                                                                        category={category}
                                                                    />
                                                                ))}
                                                        </ul>
                                                    </SimpleBar>
                                                ) : (
                                                    <h5 className="text-center mt-3">
                                                        <i>No recorded actions</i>
                                                    </h5>
                                                )}
                                            </div>
                                            <div
                                                className="bg-dark d-flex justify-content-end"
                                                style={{
                                                    height: '45px',
                                                    borderBottomLeftRadius: '3px',
                                                    borderBottomRightRadius: '3px',
                                                }}>
                                                {(alert.hasOwnProperty('_internalArchiveID')
                                                    ? checkActionPermissions(
                                                        archivedActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                    : checkActionPermissions(
                                                        clarificationsActionButtons,
                                                        requestActionPermissionWidgetName,
                                                        pharmacies,
                                                        alert.pharmId,
                                                        alert.facID
                                                    )
                                                ).map((button, i) => (
                                                    <ActionButton
                                                        handleOnClick={handleActionButtons}
                                                        icon={button.icon}
                                                        title={button.title}
                                                        index={i}
                                                        action={button.action}
                                                        disabled={button.disable}
                                                    />
                                                ))}
                                                <ActionSendModal
                                                    isOpen={sendModalInfo.isOpen}
                                                    action={sendModalInfo.action}
                                                    toggle={toggleSendModal}
                                                    color={sendModalInfo.color}
                                                    label={sendModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'openBatch'}
                                                />
                                                <ActionDoNotSendModal
                                                    isOpen={doNotSendModalInfo.isOpen}
                                                    action={doNotSendModalInfo.action}
                                                    toggle={toggleDoNotSendModal}
                                                    color={doNotSendModalInfo.color}
                                                    label={doNotSendModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'openBatch'}
                                                />
                                                <ActionQuestionModal
                                                    isOpen={questionModalInfo.isOpen}
                                                    action={questionModalInfo.action}
                                                    toggle={toggleQuestionModal}
                                                    color={questionModalInfo.color}
                                                    label={questionModalInfo.label}
                                                    submitAction={handleUserAction}
                                                    loading={loadingUserAction}
                                                    widget={'openBatch'}
                                                />
                                            </div>
                                            {/* <Row>
                                    <Col>
                                            <Row>
                                                <Col className="mb-2 mb-sm-0">
                                                    <FormGroup>
                                                    <Label for="newMessage">Comment</Label>
                                                    <Input
                                                        value={actionComment}
                                                        onChange={(e) => setActionComment(e.target.value)}
                                                        type="text"
                                                        name="newMessage"
                                                        placeholder='Comment...'
                                                    ></Input>
                                                    </FormGroup>
                                                </Col> */}
                                            {/* <Col className="col-sm-auto">
                                                    <div className="btn-group">
                                                        <Link to="#" className="btn btn-light">
                                                            <i className="uil uil-paperclip"></i>
                                                        </Link>
                                                        <Link to="#" className="btn btn-light">
                                                            {' '}
                                                            <i className="uil uil-smile"></i>{' '}
                                                        </Link>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success chat-send btn-block">
                                                            <i className="uil uil-message"></i>
                                                        </button>
                                                    </div>
                                                </Col> */}
                                            {/* </Row>
                                            <Row>
                                                <Col className='d-flex justify-content-end mt-2'>
                                                <Button color="warning" className='mr-3'
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('ask', 'HighCost', alert._internalID)}
                                                    >
                                                        Ask
                                                    </Button>{' '}
                                                    <Button color="success" className='mr-3'
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('approve', 'HighCost', alert._internalID)}
                                                    >
                                                        Approve
                                                    </Button>{' '}
                                                    <Button 
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('approveOnce', 'HighCost', alert._internalID)}
                                                    color="secondary" size='sm' style={{ whiteSpace: 'nowrap' }} className="mr-3">
                                                        Approve Once
                                                    </Button>
                                                    <Button color="danger"
                                                    disabled={loadingUserAction}
                                                    onClick={() => handleUserAction('deny', 'HighCost', alert._internalID)}
                                                    >
                                                        Deny
                                                    </Button>  
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row> */}
                                        </Col>
                                    </Row>
                                }
                            </TabPane>
                        </TabContent>
                    </>
                )}
                {stateName === 'patMissingList' && (
                    <>
                        <div className="d-flex">
                            <div>
                                Admission Date: <b>{getDateTime(alert.admDate)}</b>
                            </div>
                        </div>
                        <hr />
                        <div>
                            Facility Name:{' '}
                            <b>
                                ({alert.facID}) {alert.facName}
                            </b>
                        </div>
                        <div>
                            Patient Name: <b>{alert.patName}</b>
                        </div>
                        <div>
                            <span>
                                Nursing Station: <b>{alert.nsid}</b>
                            </span>{' '}
                            <span className="ml-2">
                                Room: <b>{alert.room}</b>
                            </span>
                        </div>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

const ActionButton = ({ icon, handleOnClick, title, index, action, disabled }) => {
    return (
        <div
            className={`d-flex justify-content-center align-items-center ${!disabled && 'action-button'}`}
            style={{ height: '45px', width: '45px' }}
            onClick={() => {
                !disabled && handleOnClick(action);
            }}
            id={'tooltip' + index}>
            <UncontrolledTooltip placement="top" target={'tooltip' + index}>
                {title}
            </UncontrolledTooltip>
            <Icon icon={icon} width="25" />
        </div>
    );
};

const ActionCommentModal = ({ isOpen, toggle, action, color, label, submitAction, loading, widget }) => {
    const [comment, setComment] = useState('');

    function preToggle() {
        setComment('');
        toggle();
    }

    useEffect(() => {
        setComment('');
    }, [isOpen]);

    const textAreaRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                textAreaRef.current.focus();
            }, 500);
        }
    }, [isOpen]);

    return (
        <Modal size="sm" style={{ marginTop: '100px' }} isOpen={isOpen} toggle={preToggle}>
            <ModalHeader
                className="gradient-modal"
                toggle={preToggle}
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {label}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>
                        Add comment: <small>not required</small>
                    </Label>
                    <Input
                        innerRef={textAreaRef}
                        type="textarea"
                        rows={2}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}></Input>
                </FormGroup>
                <FormGroup className="d-flex justify-content-end mb-0">
                    <Button color={color} disabled={loading} onClick={() => submitAction(action, comment, widget)}>
                        {label}
                    </Button>
                </FormGroup>
            </ModalBody>
        </Modal>
    );
};

const ActionSendModal = ({ isOpen, toggle, action, color, label, submitAction, loading, widget }) => {
    const [selectedReason, setSelectedReason] = useState(null);

    const [comment, setComment] = useState('');

    function preToggle() {
        setValidState({
            selectedReason: true,
        });
        setSelectedReason(null);
        setComment('');
        toggle();
    }

    useEffect(() => {
        setSelectedReason(null);
        setComment('');
    }, [isOpen]);

    const [validState, setValidState] = useState({
        selectedReason: true,
    });

    const sendReasons = [
        { value: 'MD aware of interaction and approved', label: 'MD aware of interaction and approved' },
        { value: 'MD aware of allergy and approved', label: 'MD aware of allergy and approved' },
        { value: 'MD Okayed', label: 'MD Okayed' },
        { value: 'other', label: 'Other. See comment...' },
    ];

    function beforeSubmitValidation() {
        let valid = true;

        if (!selectedReason) {
            setValidState({
                selectedReason: false,
            });
            valid = false;
        }

        return valid;
    }

    return (
        <Modal size="sm" style={{ marginTop: '100px' }} isOpen={isOpen} toggle={preToggle}>
            <ModalHeader
                className="gradient-modal"
                toggle={preToggle}
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {label}
            </ModalHeader>
            <ModalBody>
                <AvForm
                    onValidSubmit={() => {
                        if (!beforeSubmitValidation()) return;
                        submitAction(action, selectedReason === 'other' ? comment : selectedReason, widget);
                    }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label
                                    for="pharmacy-role"
                                    style={{ color: `${!validState.selectedReason ? '#fa5c7c' : ''}` }}>
                                    Select Reason *
                                </Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    className={`${!validState.selectedReason ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    name="select-reason"
                                    placeholder="Select reason..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            selectedReason: true,
                                        });
                                        setSelectedReason(selection.value);
                                    }}
                                    value={sendReasons?.find((reason) => reason.value === selectedReason) || ''}
                                    isSearchable={false}
                                    options={sendReasons}></Select>
                                {/* </div> */}
                                {!validState.selectedReason && (
                                    <div className="invalid-text">Please select a reason</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    {selectedReason === 'other' && (
                        <AvField
                            className="validation-textarea"
                            type="textarea"
                            name="comment"
                            label="Enter Comment *"
                            placeholder="Comment..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                        />
                    )}
                    <FormGroup className="d-flex justify-content-end mb-0">
                        <Button color={color} disabled={loading}>
                            {label}
                        </Button>
                    </FormGroup>
                </AvForm>
            </ModalBody>
        </Modal>
    );
};

const ActionDoNotSendModal = ({ isOpen, toggle, action, color, label, submitAction, loading, widget }) => {
    const [selectedReason, setSelectedReason] = useState(null);

    function preToggle() {
        setSelectedReason(null);
        setValidState({
            selectedReason: true,
        });
        toggle();
    }

    useEffect(() => {
        setSelectedReason(null);
    }, [isOpen]);

    const [validState, setValidState] = useState({
        selectedReason: true,
    });

    const sendReasons = [
        {
            value: 'new Rx of change order will be sent to pharmacy',
            label: 'new Rx of change order will be sent to pharmacy',
        },
        { value: 'd/c order will be sent to pharmacy', label: 'd/c order will be sent to pharmacy' },
    ];

    function beforeSubmitValidation() {
        let valid = true;

        if (!selectedReason) {
            setValidState({
                selectedReason: false,
            });
            valid = false;
        }

        return valid;
    }

    return (
        <Modal size="sm" style={{ marginTop: '100px' }} isOpen={isOpen} toggle={preToggle}>
            <ModalHeader
                className="gradient-modal"
                toggle={preToggle}
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {label}
            </ModalHeader>
            <ModalBody>
                <AvForm
                    onValidSubmit={() => {
                        if (!beforeSubmitValidation()) return;
                        submitAction(action, selectedReason, widget);
                    }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label
                                    for="pharmacy-role"
                                    style={{ color: `${!validState.selectedReason ? '#fa5c7c' : ''}` }}>
                                    Select Reason *
                                </Label>
                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                <Select
                                    className={`${!validState.selectedReason ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    name="select-reason"
                                    placeholder="Select reason..."
                                    onChange={(selection) => {
                                        setValidState({
                                            ...validState,
                                            selectedReason: true,
                                        });
                                        setSelectedReason(selection.value);
                                    }}
                                    value={sendReasons?.find((reason) => reason.value === selectedReason) || ''}
                                    isSearchable={false}
                                    options={sendReasons}></Select>
                                {/* </div> */}
                                {!validState.selectedReason && (
                                    <div className="invalid-text">Please select a reason</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="d-flex justify-content-end mb-0">
                        <Button color={color} disabled={loading}>
                            {label}
                        </Button>
                    </FormGroup>
                </AvForm>
            </ModalBody>
        </Modal>
    );
};

const ActionQuestionModal = ({ isOpen, toggle, action, color, label, submitAction, loading, widget }) => {
    const [question, setQuestion] = useState('');

    function preToggle() {
        setQuestion('');
        toggle();
    }

    useEffect(() => {
        setQuestion('');
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                let textarea = document.querySelector('.validation-textarea');
                textarea.focus();
            }, 500);
        }
    }, [isOpen]);

    return (
        <Modal size="sm" style={{ marginTop: '100px' }} isOpen={isOpen} toggle={preToggle}>
            <ModalHeader
                className="gradient-modal"
                toggle={preToggle}
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {label}
            </ModalHeader>
            <ModalBody>
                <AvForm onValidSubmit={() => submitAction(action, question, widget)}>
                    <AvField
                        className="validation-textarea"
                        type="textarea"
                        name="question"
                        label="Enter Question *"
                        placeholder="Question..."
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                    />
                    <FormGroup className="d-flex justify-content-end mb-0">
                        <Button color={color} disabled={loading}>
                            {label}
                        </Button>
                    </FormGroup>
                </AvForm>
            </ModalBody>
        </Modal>
    );
};

const ActionLimitedApprovalModal = ({ isOpen, toggle, action, color, label, submitAction, loading, widget }) => {
    const [comment, setComment] = useState('');

    function preToggle() {
        toggle();
    }

    useEffect(() => {
        setComment('');
    }, [isOpen]);

    const textAreaRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                textAreaRef.current.focus();
            }, 500);
        }
    }, [isOpen]);

    const dayOptions = [2, 3, 5, 7];

    return (
        <Modal size="sm" style={{ marginTop: '100px' }} isOpen={isOpen} toggle={preToggle}>
            <ModalHeader
                className="gradient-modal"
                toggle={preToggle}
                style={{
                    background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                    color: 'white',
                }}>
                {label}
            </ModalHeader>
            <ModalBody>
                {/* <p>
                    <b>Please select days for approval</b>
                </p> */}
                <FormGroup>
                    <Label>
                        Add comment: <small>not required</small>
                    </Label>
                    <Input
                        innerRef={textAreaRef}
                        type="textarea"
                        rows={2}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}></Input>
                </FormGroup>
                <div className="d-flex justify-content-between">
                    {dayOptions.map((option) => (
                        <Button
                            color={'secondary'}
                            style={{ whiteSpace: 'nowrap', fontSize: '11px' }}
                            onClick={() => submitAction(action, comment, widget, option)}
                            disabled={loading}>
                            {option} Days
                        </Button>
                    ))}
                </div>
                <div className="mt-2">
                    <Button color="link" className="p-0" disabled>
                        <small>More options...</small>
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

const ActionMessage = ({ action, i, alert, category }) => {
    const { user } = useContext(UserContext);

    const messageRef = useRef(null);
    const isVisible = useOnScreen(messageRef);

    const { currentTime } = useContext(CurrentTimeContext);

    const { updateReadStatus, updateMessageReadStatus } = useUpdateAlert();

    const changeActionMessageReadStatus = async (status) => {
        SrxApi.post('/requestaction_readByFaci', {
            requestActionID: action.requestActionID,
            readByFaci: status,
        })
            .then((res) => {
                updateMessageReadStatus(status, action, alert, category);
            })
            .catch((err) => toast.error('Error updating read status'));
    };

    useEffect(() => {
        if (isVisible) {
            if (action.action.toLowerCase() === 'reply' && !action.readByFaci) {
                changeActionMessageReadStatus(true);
            }
        }
    }, [isVisible]);

    function difference2Parts(date) {
        const secs = Math.floor(Math.abs(currentTime - date) / 1000);
        const mins = Math.floor(secs / 60);
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        // const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;
        // const multiple = (term, n) => n !== 1 ? `${n} ${term}s` : `1 ${term}`;

        return {
            days: days,
            hours: hours % 24,
            hoursTotal: hours,
            minutesTotal: mins,
            minutes: mins % 60,
            seconds: secs % 60,
            secondsTotal: secs,
            //   milliSeconds: millisecs,
            //   get diffStr() {
            //     return `${multiple(`day`, this.days)}, ${
            //       multiple(`hour`, this.hours)}, ${
            //       multiple(`minute`, this.minutes)} and ${
            //       multiple(`second`, this.seconds)}`;
            //   },
            //   get diffStrMs() {
            //     return `${this.diffStr.replace(` and`, `, `)} and ${
            //       multiple(`millisecond`, this.milliSeconds)}`;
            //   },
        };
    }

    function showDifferenceInTime(date) {
        let difference = difference2Parts(date);
        if (difference.days) return difference.days > 1 ? difference.days + ' days ago' : difference.days + ' day ago';

        if (difference.hours)
            return difference.hours > 1 ? difference.hours + ' hours ago' : difference.hours + ' hour ago';

        if (difference.minutes)
            return difference.minutes > 1 ? difference.minutes + ' minutes ago' : difference.minutes + ' minute ago';

        return 'Just now';
    }

    return (
        <div ref={messageRef}>
            <li className={classNames('clearfix', { odd: action.requestedBy === user.displayName })}>
                <div className="chat-avatar">
                    {/* <img src={userPic} className="rounded" alt="" style={{ hieght: '40px', width: '40px' }}/> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 3 24 24">
                        <path
                            fill="currentColor"
                            d="M6 17c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6m9-9a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2Z"
                        />
                    </svg>
                </div>
                <div className="conversation-text">
                    <div className="ctext-wrap">
                        <i>{action.requestedBy}</i>
                        <p>{action.actionComment}</p>
                        {getActionLabel(action.action) && (
                            <h5 className="mt-1 mb-0">
                                <Badge className={'bg-' + getUserActionBadgeColor(action.action)}>
                                    {getActionLabel(action.action)}
                                </Badge>
                            </h5>
                        )}
                    </div>
                    <div className="action-message-time">
                        {showDifferenceInTime(new Date(action.sentTime))} ~ {new Date(action.sentTime).toLocaleString()}
                    </div>
                </div>
            </li>
        </div>
    );
};

const ActionMessageA = ({ action, i, alert, category }) => {
    const messageRef = useRef(null);
    const isVisible = useOnScreen(messageRef);

    const { currentTime } = useContext(CurrentTimeContext);

    const { updateReadStatus, updateMessageReadStatus } = useUpdateAlert();

    const changeActionMessageReadStatus = async (status) => {
        SrxApi.post('/requestaction_readByFaci', {
            requestActionID: action.requestActionID,
            readByFaci: status,
        })
            .then((res) => {
                updateMessageReadStatus(status, action, alert, category);
            })
            .catch((err) => toast.error('Error updating read status'));
    };

    useEffect(() => {
        if (isVisible) {
            if (action.action.toLowerCase() === 'reply' && !action.readByFaci) {
                changeActionMessageReadStatus(true);
            }
        }
    }, [isVisible]);

    function difference2Parts(date) {
        const secs = Math.floor(Math.abs(currentTime - date) / 1000);
        const mins = Math.floor(secs / 60);
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        // const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;
        // const multiple = (term, n) => n !== 1 ? `${n} ${term}s` : `1 ${term}`;

        return {
            days: days,
            hours: hours % 24,
            hoursTotal: hours,
            minutesTotal: mins,
            minutes: mins % 60,
            seconds: secs % 60,
            secondsTotal: secs,
            //   milliSeconds: millisecs,
            //   get diffStr() {
            //     return `${multiple(`day`, this.days)}, ${
            //       multiple(`hour`, this.hours)}, ${
            //       multiple(`minute`, this.minutes)} and ${
            //       multiple(`second`, this.seconds)}`;
            //   },
            //   get diffStrMs() {
            //     return `${this.diffStr.replace(` and`, `, `)} and ${
            //       multiple(`millisecond`, this.milliSeconds)}`;
            //   },
        };
    }

    function showDifferenceInTime(date) {
        let difference = difference2Parts(date);
        if (difference.days) return difference.days > 1 ? difference.days + ' days ago' : difference.days + ' day ago';

        if (difference.hours)
            return difference.hours > 1 ? difference.hours + ' hours ago' : difference.hours + ' hour ago';

        if (difference.minutes)
            return difference.minutes > 1 ? difference.minutes + ' minutes ago' : difference.minutes + ' minute ago';

        return 'Just now';
    }

    return (
        <div ref={messageRef}>
            <Media className={'mt-3 mb-2'}>
                <div
                    className="mr-2 rounded-circle bg-light d-flex justify-content-center align-items-center"
                    style={{
                        minHeight: '35px',
                        maxHeight: '35px',
                        minWidth: '35px',
                        maxWidth: '35px',
                        overflow: 'hidden',
                    }}>
                    <Icon icon={accountIcon} width="27" height="27" />
                </div>
                <Media body>
                    <div className="d-flex justify-content-between">
                        <h5 className="m-0">{action.requestedBy}</h5>
                        <Badge
                            style={{ fontSize: '12px' }}
                            className={'badge badge-outline-' + getUserActionBadgeColor(action.action)}>
                            {getActionLabel(action.action)}
                        </Badge>
                    </div>
                    <p className="text-muted mb-0">
                        <small>
                            <b>{showDifferenceInTime(new Date(action.sentTime))}</b>
                        </small>{' '}
                        ~ <small>{new Date(action.sentTime).toLocaleString()}</small>
                    </p>
                    {action.actionComment && (
                        <div className="mt-1">
                            <b>
                                <i>&#8220;{action.actionComment}&#8221;</i>
                            </b>
                        </div>
                    )}
                </Media>
            </Media>
            {i !== alert.requestedActions?.length - 1 && <hr className="mt-0" />}
            {alert.requestedActions?.length === 1 && <hr className="mt-0" />}
        </div>
    );
};

export default TableRowDetailModal;
