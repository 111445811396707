import React, { useContext } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { highCostAtom, highCostAtomFilteredCount, highCostAtomFiltered, highCostAtomShownAlerts, 
    highCostAlertsAtom, highCostAlertsAtomFilteredCount, highCostAlertsAtomFiltered, highCostAlertsAtomShownAlerts,
    clarificationsAtom, clarificationsAtomFilteredCount, clarificationsAtomFiltered, clarificationsAtomShownAlerts,
    priorAuthAtom, priorAuthAtomFilteredCount, priorAuthAtomFiltered, priorAuthAtomShownAlerts,
    nonCoveredAtom, nonCoveredAtomFilteredCount, nonCoveredAtomFiltered, nonCoveredAtomShownAlerts,
    therapeuticIntAtom, therapeuticIntAtomFilteredCount, therapeuticIntAtomFiltered, therapeuticIntAtomShownAlerts,
    admissionMedsAtom, admissionMedsAtomFilteredCount, admissionMedsAtomFiltered, admissionMedsAtomShownAlerts,
    patMissingAtom, patMissingAtomFilteredCount, patMissingAtomFiltered, patMissingAtomShownAlerts } from '../jotai-atoms/AlertAtoms';
import { UserContext } from '../contexts/UserContext';
import { SrxApi } from '../api/SrxApi';

const useRefreshAlerts = () => {

    const { faciToken, setFaciToken } = useContext(UserContext);

    let history = useHistory()

    const [highCostAlerts, setHighCostAlerts] = useAtom(highCostAtom);
    const [highCostAlertsAlerts, setHighCostAlertsAlerts] = useAtom(highCostAlertsAtom);
    const [nonCoveredAlerts, setNonCoveredAlerts] = useAtom(nonCoveredAtom);
    const [clarificationsAlerts, setClarificationsAlerts] = useAtom(clarificationsAtom);
    const [priorAuthAlerts, setPriorAuthAlerts] = useAtom(priorAuthAtom);
    const [therapeuticIntAlerts, setTherapeuticIntAlerts] = useAtom(therapeuticIntAtom);
    const [admissionMedsAlerts, setAdmissionMedsAlerts] = useAtom(admissionMedsAtom);
    const [patMissingAlerts, setPatMissingAlerts] = useAtom(patMissingAtom);

    const getRequests = [
        {
            path: 'highCost',
            stateName: 'highCostList',
            setter: setHighCostAlerts,
            getter: highCostAlerts,
            refreshSchedule: {
                mins: 0,
                scnds: 0,
            }
        },
        {
            path: 'highcostalerts',
            stateName: 'highCostAlertsListFlat',
            setter: setHighCostAlertsAlerts,
            getter: highCostAlertsAlerts,
            refreshSchedule: {
                mins: 0,
                scnds: 0,
            }
        },
        {
            path: 'openbatch',
            stateName: 'openBatchList',
            setter: setClarificationsAlerts,
            getter: clarificationsAlerts,
            refreshSchedule: {
                mins: 6,
                scnds: 40,
            }
        },
        {
            path: 'nonCovered',
            stateName: 'nonCoveredList',
            setter: setNonCoveredAlerts,
            getter: nonCoveredAlerts,
            refreshSchedule: {
                mins: 1,
                scnds: 20,
            }
        },
        {
            path: 'priorAuth',
            stateName: 'priorAuthList',
            setter: setPriorAuthAlerts,
            getter: priorAuthAlerts,
            refreshSchedule: {
                mins: 2,
                scnds: 40,
            }
        },
        {
            path: 'therapeuticint',
            stateName: 'therapeuticInterchangeList',
            setter: setTherapeuticIntAlerts,
            getter: therapeuticIntAlerts,
            refreshSchedule: {
                mins: 4,
                scnds: 0,
            }
        },
        {
            path: 'admissionmeds',
            stateName: 'admissionMedsList',
            setter: setAdmissionMedsAlerts,
            getter: admissionMedsAlerts,
            refreshSchedule: {
                mins: 5,
                scnds: 20,
            }
        },
        {
            path: 'patmissing',
            stateName: 'patMissingList',
            setter: setPatMissingAlerts,
            getter: patMissingAlerts,
            refreshSchedule: {
                mins: 8,
                scnds: 0,
            }
        }
    ]

    const getData = async ({path, getter, setter, stateName}) => {
        // let { loading, data, error } = useSrxAxios(path);

        // if(!getter.shownAlerts)
        // setter({
        //     ...getter,
        //     loading: true
        // })

        try{
            setter({
                ...getter,
                loading: true
            })

            let { data } = await SrxApi.get('/' + path)

            setter({
                ...getter,
                loading: false,
                alertData: data[stateName],
                lastRefreshed: data.timeStamp,
                error: false
            })
        } catch(err){
            if(err.response?.status === 401){
                localStorage.removeItem('FACI_TOKEN');
                setFaciToken(null);
                history.replace('/account/login')
            } else {
                setter({
                    ...getter,
                    loading: false,
                    error: true
                })
            }
        }
    }

    function refreshWidget(widgetPath){
        let currentRequest = getRequests.find(({path}) => path.toLowerCase() === widgetPath.toLowerCase())
        
        if(currentRequest){
            getData(currentRequest)
        }
    }

    return {
        refreshWidget
    }
}

export default useRefreshAlerts;