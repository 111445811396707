import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Badge,
    Button,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import { UserContext } from '../contexts/UserContext';
import { Icon } from '@iconify/react';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import SimpleBar from 'simplebar-react';
import printerIcon from '@iconify/icons-mdi/printer';
import searchIcon from '@iconify/icons-mdi/magnify';
import closeThick from '@iconify/icons-mdi/close-thick';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { SrxApi } from '../api/SrxApi';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import FlatPicker from 'react-flatpickr';
import "flatpickr/dist/themes/dark.css";

const PackingSlips = () => {
    const [totes, setTotes] = useState([]);
    const [activeTote, setActiveTote] = useState({ facID: null, toteID: null });
    const [toteDetailed, setToteDetailed] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [isPrinting, setIsPrinting] = useState(false);
    let history = useHistory();
    let location = useLocation();
    const datePickerRef = useRef();

    const getTotes = (fromDate, toDate, search) => {
        setLoading(true)
        SrxApi.get(`/packingslipheaders${(fromDate && toDate) ? `?fdate=${fromDate}&edate=${toDate}` : (search ? `?SearchText=${search}` : '')}`)
            .then(res => {
                setTotes(res.data.packingSlipHeadersList)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                console.log('error');
                setTotes([])
                setLoading(false)
            })
    }

    const getToteDetails = (facID, toteID) => {
        // SrxApi.get(`/packingslipdetails?facID=${'LICC'}&toteID=${'C2052260'}`)
        SrxApi.get(`/packingslipdetails?facID=${facID}&toteID=${toteID}`)
            .then(res => {
                console.log(res);
                console.log('dfnjk');
                setToteDetailed(res);
            })
            .catch(err => {
                console.log(err);
                console.log('error');
            })
    }

    useEffect(() => {
        getTotes();
    }, [])

    // useEffect(() => {
    //     const query = new URLSearchParams(location.search);

    //     let facID = query.get('facID');
    //     let toteID = query.get('toteID');
    //     if (toteID && facID) {
    //         setActiveTote({ facID, toteID });
    //     } else {
    //         setActiveTote({ faciID: null, toteID: null })
    //         setToteDetailed(null)
    //     }

    // }, [location.search])


    // useEffect(() => {
    //     if (activeTote.facID && activeTote.toteID) {
    //         getToteDetails();
    //     } else {
    //         return
    //     }
    // }, [activeTote])

    const getFormattedDate = (date) => {
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }


    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            getTotes(undefined, undefined, search);
        }
    }

    function searchFacilities(array) {
        array.sort((a, b) => a.name < b.name ? -1 : 1)
        if (searchTerm) {
            let results = array.filter(faci => faci.facName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            return results
        } else {
            return array
        }
    }

    const [sortBy, setSortBy] = useState({
        sortField: 'facName',
        sortDirection: 'asc'
    })

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'nsName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.nsName < b.nsName ? -1 : 1)
                else
                    return array.sort((a, b) => a.nsName > b.nsName ? -1 : 1)
            case 'patCount':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.patCount < b.patCount ? -1 : 1)
                else
                    return array.sort((a, b) => a.patCount > b.patCount ? -1 : 1)
            case 'rxCount':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.rxCount < b.rxCount ? -1 : 1)
                else
                    return array.sort((a, b) => a.rxCount > b.rxCount ? -1 : 1)
            case 'shipDate':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.shipDate) > new Date(b.shipDate) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.shipDate) < new Date(b.shipDate) ? -1 : 1)
            case 'facName':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
                else
                    return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            case 'toteID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.toteID < b.toteID ? -1 : 1)
                else
                    return array.sort((a, b) => a.toteID > b.toteID ? -1 : 1)
            case 'facID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.facID < b.facID ? -1 : 1)
                else
                    return array.sort((a, b) => a.facID > b.facID ? -1 : 1)
            case 'pharmID':
                if (sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.pharmID < b.pharmID ? -1 : 1)
                else
                    return array.sort((a, b) => a.pharmID > b.pharmID ? -1 : 1)
        }
    }

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ?
                <Icon icon={arrowUp} color="black" />
                :
                <Icon icon={arrowDown} color="black" />
            }
        </>
    )

    if (!loading)
        return (
            <>
                <div className='mt-3 mx-2'>
                    <h2>Totes</h2>
                    <Card>
                        <CardBody>
                            <Row className="mb-2 d-flex justify-content-between">
                                <Col className="" md={5}>
                                    <div className='d-flex'>
                                        <FormGroup className='mb-0 d-flex'>
                                            <Label className='d-flex align-items-center mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>Date Range: </Label>
                                            <InputGroup style={{ width: '290px' }}>
                                                <FlatPicker
                                                    ref={datePickerRef}
                                                    className='form-control rounded-left bg-white'
                                                    value={dateRange ?? null}
                                                    options={
                                                        {
                                                            onChange: (selectedDates, dateStr, instance) => setDateRange(selectedDates),
                                                            maxDate: 'today',
                                                            // maxDate: archivedData.dateRange.to,
                                                            mode: "range",
                                                            altInput: true,
                                                            altFormat: "m-d-Y",
                                                            // altFormat: "F j, Y",
                                                            dateFormat: "Y-m-d",
                                                        }
                                                    }
                                                    placeholder="Choose date..."
                                                ></FlatPicker>
                                                <InputGroupAddon
                                                    addonType="append"
                                                >
                                                    <span onClick={() => {
                                                        datePickerRef.current.flatpickr.clear();
                                                        getTotes();
                                                    }} role='button' className="input-group-text py-0 px-1 bg-white">
                                                        <Icon icon={closeThick} width="25px" />
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            setSearch('');
                                                            getTotes(dateRange[0].toLocaleDateString("en-US"), dateRange[1].toLocaleDateString("en-US"), undefined)
                                                        }}
                                                        role='button'
                                                        className="input-group-text py-0 px-1">
                                                        <Icon icon={searchIcon} width="25px" />
                                                    </span>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col className="text-right" md={3}>
                                    <FormGroup className='d-flex mb-0' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <InputGroup>
                                            <Input value={search} onChange={(e) => setSearch(e.target.value)}
                                                onKeyUp={(e) => handleEnter(e)}
                                                placeholder={`Search TOT... PAK...`}>

                                            </Input>
                                            <InputGroupAddon
                                                addonType="append"
                                            >
                                                <span onClick={() => {
                                                    setSearch('');
                                                    getTotes();
                                                }} role='button' className="input-group-text py-0 px-1 bg-white">
                                                    <Icon icon={closeThick} width="25px" />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        datePickerRef.current.flatpickr.clear();
                                                        getTotes(undefined, undefined, search);
                                                    }}
                                                    role='button'
                                                    className="input-group-text py-0 px-1">
                                                    <Icon icon={searchIcon} width="25px" />
                                                </span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                {/* <Col className="text-right" md={3}>
                                    <FormGroup className='d-flex mb-0 align-items-center' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <Label className='mr-2 mb-0'><b>Search by facility name:</b></Label>
                                        <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder={`Search ${totes.length} records...`}>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <div>
                                {!totes.length ?

                                    <div style={{ minHeight: '33vh', }}>
                                        <h2 className='text-center fw-bold mt-5'>Nothing to show</h2>
                                    </div>
                                    :
                                    <SimpleBar style={{ height: '565px' }}>

                                        <Table className="mb-0" >
                                            <thead className="bg-light" style={{ position: 'sticky', top: '-1px' }}>
                                                <tr>
                                                    <th onClick={() => onClickSort('facID')} className="th-hover">Facilty ID {sortBy.sortField === 'facID' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('facName')} className="th-hover">Facility Name {sortBy.sortField === 'facName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('pharmID')} className="th-hover">Pharmacy ID {sortBy.sortField === 'pharmID' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('nsName')} className="th-hover">NS Name {sortBy.sortField === 'nsName' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('toteID')} className="th-hover">Tote ID {sortBy.sortField === 'toteID' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('shipDate')} className="th-hover">Ship Date {sortBy.sortField === 'shipDate' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('patCount')} className="th-hover">Pat. Count {sortBy.sortField === 'patCount' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                                                    <th onClick={() => onClickSort('rxCount')} className="th-hover">RX Count {sortBy.sortField === 'rxCount' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortByField(searchFacilities(totes))
                                                    .map((tote, index) => {
                                                        return (
                                                            <tr style={{ cursor: 'pointer' }} key={index}
                                                                onClick={() => {
                                                                    getToteDetails(tote.facID, tote.toteID);
                                                                    const newLocation = {
                                                                        pathname: location.pathname,
                                                                        search: `facID=${tote.facID}&toteID=${tote.toteID}`
                                                                    }

                                                                    history.push(newLocation)
                                                                }}
                                                            >
                                                                <th scope="row" style={{ verticalAlign: 'middle' }}>{tote.facID}</th>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.facName}</td>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.pharmID}</td>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.nsName} </td>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.toteID} </td>
                                                                <td style={{ verticalAlign: 'middle' }}>{getFormattedDate(tote.shipDate)}</td>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.patCount}</td>
                                                                <td style={{ verticalAlign: 'middle' }}>{tote.rxCount} </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </Table>
                                    </SimpleBar>
                                }
                            </div>
                        </CardBody>
                    </Card>
                    <ToteDetailModal
                        tote={toteDetailed}
                        isPrinting={isPrinting}
                        setIsPrinting={setIsPrinting}
                        toggle={() => {
                            setToteDetailed(null);
                            const newLocation = {
                                pathname: location.pathname
                            }
                            history.push(newLocation)
                        }}
                    />
                </div>
            </>
        );

    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
                <h4>Loading Totes...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
};

const ToteDetailModal = ({ tote, toggle, isPrinting, setIsPrinting }) => {
    const printRef = useRef();
    const getFormattedDate = (date) => {
        let onlyDate = new Date(date).toLocaleDateString();
        return onlyDate;
    }

    function getDateAndTimeStamp(date) {
        let DateAndTime = new Date(date).toLocaleTimeString([], {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
        return DateAndTime;
    }

    const handleBeforeGetContent = () => {
        setIsPrinting(true);
        return Promise.resolve();
    }

    return (
        <Modal
            style={{ maxWidth: '900px', width: '900px' }}
            centered
            isOpen={tote}
            toggle={toggle}
        >
            {tote ?
                <>
                    <ModalHeader
                        className="gradient-modal my-auto"
                        toggle={toggle}
                        style={{
                            background: "linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)",
                            color: 'white'
                        }}
                    >
                        Tote ID: {tote.data[0][0]?.toteID}

                        <ReactToPrint
                            trigger={() => <i role='button' style={{ fontSize: '20px' }} title='Print' className='mdi mdi-printer py-0 px-0 ml-2'></i>}
                            content={() => printRef.current}
                            documentTitle={`Tote ID #TOT${tote.data[0][0]?.toteID}`}
                            onBeforeGetContent={() => handleBeforeGetContent()}
                            onAfterPrint={() => setIsPrinting(false)}
                        />
                    </ModalHeader>
                    <ModalBody>
                        <div id="printDiv" ref={printRef}>
                            {tote?.data?.map((psid, index) => {

                                return (<>
                                    {psid?.map((nsid, index) => {
                                        return (
                                            <div key={index} className='single-slip' style={{ borderBottom: '1px dashed #dee2e6', marginBottom: '1.75rem' }}>
                                                <div>
                                                    <h4 className='mt-0'>
                                                        <span className='mb-0 pl-1 pr-3'>NS: <b className='mr-3'>{nsid.nsName} ({nsid.nsID})</b>
                                                            Date: <b className='mr-4'>{getFormattedDate(nsid.shipDate)}</b>
                                                            Tote ID:  <b>{tote.data[0][0]?.toteID}</b>
                                                            <b className='float-right bg-light px-1 rounded'>Packing Slip</b>
                                                        </span>
                                                    </h4>
                                                </div>
                                                <div className='bg-secondary text-white pl-1'>Deliver To:</div>
                                                <div className='border py-1 mb-1'>
                                                    <Row>
                                                        <Col>
                                                            <p className='mb-0 pl-1'><b>{nsid.facName} ({nsid.facID})</b></p>
                                                            <p className='mb-0 pl-1'><b>{nsid.facStreet1} <br />{nsid.facCity} {nsid.facState} {nsid.facZip}</b></p>
                                                            <p className='mb-0 pl-1'>Delivery Route: <b>{nsid.deliveryRoute ?? '-'}</b></p>
                                                            <p className='mb-0 pl-1'>
                                                                {/* <Barcode fontSize='15' width='1' height='50' value={`TOT${t?.toteID}`} /> */}
                                                                <Barcode fontSize={isPrinting ? '17' : '15'}
                                                                    width={isPrinting ? '2' : '1'}
                                                                    height={isPrinting ? '100' : '50'}
                                                                    value={`TOT${nsid?.toteID}`} />
                                                            </p>
                                                        </Col>
                                                        <Col className='border-left'>
                                                            <p className='mb-0'><b>{nsid.pharmName} ({nsid.pharmID})</b></p>
                                                            <p className='mb-0'><b>{nsid.pharmStreet1} <br />{nsid.pharmCity} {nsid.pharmState} {nsid.pharmZip}</b></p>
                                                            <p className='mb-0'><b>{nsid.pharmPhone}</b></p>
                                                            <p className='mb-0 pl-1 align-right'>
                                                                {/* <Barcode fontSize='15' width='1' height='50' value={`PAK${t.psid}`} /> */}
                                                                <Barcode fontSize={isPrinting ? '17' : '15'}
                                                                    width={isPrinting ? '2' : '1'}
                                                                    height={isPrinting ? '100' : '50'}
                                                                    value={`PAK${nsid.psid}`} />
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {nsid?.legalHeader && <div className='text-center'><h3>{nsid?.legalHeader}</h3></div>}
                                                <div>
                                                    {nsid?.patientDetails?.map(slip => {
                                                        return (<div className=''>
                                                            <div className='pl-1 mt-0'>
                                                                <b className='mr-2'>{slip.patName} </b><span className='mr-2'>({slip.patID})</span>
                                                                Rm:<b className='mr-2'>{slip.room}</b>
                                                                Bed:<b>{slip.bed}</b>
                                                            </div>
                                                            <div className='pl-1 pt-0 patient-drug-details mb-4'>

                                                                {slip?.drugDetails?.map(d => {
                                                                    return (<>
                                                                        <div className='single-drug'>
                                                                            <Row className='drug-info-line'>
                                                                                <Col xs={3} className='pl-2'>
                                                                                    <div className='pl-2'>Rx:<b className='mr-1'>{d.rxNo}</b> Ro:<b>{d.roNo}</b></div>
                                                                                </Col>
                                                                                <Col xs={5}>
                                                                                    <b>
                                                                                        {d.deaClass?.trim().length ?
                                                                                            <Badge title='dea class' className='bg-danger mr-1 dea-class' pill >{d?.deaClass}</Badge> : null}
                                                                                        Qty: <b>{d.qty}</b> &nbsp; - &nbsp;
                                                                                        {d.drugLabelName}
                                                                                    </b>
                                                                                </Col>
                                                                                <Col xs={4}>
                                                                                    By:<b>{d.phName}</b>
                                                                                    {nsid.showDeliveryInfo &&
                                                                                        <span className='float-right mr-1'>
                                                                                            <Badge title='delivery status'
                                                                                                className={`delivery-status badge-outline-${d.delivered ? 'success' : 'warning'}`}
                                                                                                pill
                                                                                            >
                                                                                                {d.delivered ? 'Delivered' : 'Not delivered'}
                                                                                            </Badge>
                                                                                        </span>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className='pl-2'>
                                                                                    <div className='pl-2 drug-note'>
                                                                                        {d.newRx && <b className='mr-1'>{d.newRx}</b>}
                                                                                        {d.serialNo &&
                                                                                            <>
                                                                                                Serial No:
                                                                                                <Badge title='serial number' className='bg-secondary mx-1 dea-class' pill >
                                                                                                    {d.serialNo}
                                                                                                </Badge>
                                                                                            </>}
                                                                                        {d.packSlipMsg ? `*** ${d.packSlipMsg} ***` : ''}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>)
                                                    })}
                                                </div>
                                                {nsid.showDeliveryInfo &&
                                                    <div className='border mb-2'>
                                                        <Row className=''>
                                                            <Col xs={6} className='pl-3'>
                                                                <p className='mt-1 mb-0'>Driver: <b >{nsid.deliveredBy ?? '-'}</b></p>
                                                                <p className='my-1'>Received By: <b >{nsid.receivedBy ?? '-'}</b>
                                                                    {nsid.receivedDateTime ? <span> on <b>{getDateAndTimeStamp(nsid.receivedDateTime)}</b></span> : ''}
                                                                </p>
                                                                <p className='mb-1'>Signature Title: <b >{nsid.signorTitle ?? '-'}</b></p>
                                                            </Col>

                                                            <Col xs={6} className='mt-auto mb-1'>
                                                                <span className='mr-1 align-bottom'>Signature: </span>
                                                                <span>
                                                                    {nsid.signature && <img style={{ objectFit: 'contain ' }} className='border-bottom mr-1' width="auto" height="60"
                                                                        src={`data:image/png;base64,${nsid.signature}`} alt="received by signature" />}
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                            </div>)
                                    })}
                                </>)

                            })}
                        </div>
                    </ModalBody>
                </>
                :
                ''
            }
        </Modal>
    )
}


export default PackingSlips;